.navbar {
	display: grid;
	grid-auto-flow: column;
	grid-template-rows: $navbar-height;
	width: 100%;
	height: $navbar-height;
	align-items: center;
	position: relative;
	padding: 0 1rem;
	font-weight: $weight-semibold;
	border-bottom: 1px solid $border;

	@include tablet() {
		grid-template-rows: $navbar-height + 1rem;
		height: $navbar-height + 1rem;
	}

	.burger {
		button {
			// display: none;
			outline: none;
			border: 0;
			color: $primary;
			background: transparent;
			font-size: 2rem;
		}

		@include desktop() {
			display: none;
		}
	}
}

.navbar-actions {
	display: grid;
	grid-auto-flow: column;

	border-bottom: 1px solid $border;
	height: $navbar-height;

	padding: 0.25rem 1rem;
	align-content: center;

	border-left: 1px solid $border;
}
