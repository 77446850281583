.ziele {
	display: grid;
	grid-template-rows: 1fr max-content;
	height: 100%;
}

.ziele-list {
	border: 1px solid $border;
	padding: 0.5rem;
	border-radius: $radius;
	max-width: 300px;

	.ziele-list-item {
		background: $yellow;
		border-radius: $radius;
		padding: 0.25rem 0.75rem;
		display: inline-grid;
		grid-template-columns: 1fr max-content;
		align-items: center;
		margin: 0 0.25rem 0.25rem 0;

		.is-delete-button {
			padding: 0;
			padding-left: 0.5rem;
		}
	}
}

.ziele-input {
	padding: 0.5rem 0 0;
	display: grid;
	grid-template-columns: 1fr max-content;

	.form-control {
		margin: 0;
		input {
			border-radius: $radius 0 0 $radius;
		}
	}

	.button {
		border-radius: 0 $radius $radius 0;
	}
}
