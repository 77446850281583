.file-uploader {
	// label {
	// 	@extend .inputbase;
	// 	position: relative;
	// 	overflow: hidden;
	// 	color: $grey-light;

	// 	&:after {
	// 		content: "+";
	// 		position: absolute;
	// 		width: 36px;
	// 		height: 36px;
	// 		right: 0;
	// 		top: 0;
	// 		text-align: center;
	// 		font-size: 26px;
	// 		line-height: 32px;
	// 		text-align: center;
	// 		color: $grey-light;
	// 	}
	// }

	// input[type="file"] {
	// 	position: absolute;
	// 	opacity: 0;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	outline: none;
	// }

	// &.is-active {
	// 	label {
	// 		color: $text;
	// 	}
	// }

	.body {
		cursor: pointer;

		&:hover,
		&:focus {
			color: $primary;
		}
	}

	&.is-button {
		@extend .button;

		.body {
			display: grid;
			grid-auto-flow: column;
			gap: 0.5rem;
		}
	}

	&.is-inline {
		.body {
			display: grid;
			gap: 0.5rem;
			grid-auto-flow: column;
			justify-content: start;
		}
	}

	.overlay {
		position: fixed;
		background: rgba($color: $white, $alpha: 0.85);
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 999;
		display: grid;
		grid-auto-flow: row;
		align-content: center;
		justify-content: center;
		text-align: center;
		gap: 1rem;
		color: $action;

		.icon {
			font-size: 2rem;
		}
		.text {
			font-size: $size-4;
		}
	}
}
