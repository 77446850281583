.planung-panel-body {
	background: $grey-bg;
}

.panel-navigation {
	.button {
		margin: 0;
		background: transparent !important;
		border-radius: 0;
		box-shadow: none;
		border-bottom: 0.25rem solid transparent;

		&:hover,
		&:focus {
			border-bottom-color: $action;
			color: $action;
		}

		&.is-active {
			border-bottom-color: $primary;
			color: $primary;
		}
	}
}

.planung-year-select {
	padding-left: 1rem;

	@include mobile() {
		padding-left: 0;
		margin-top: 0.25rem;
		border-bottom: 1px solid $border;
	}
}

.planung-actions {
	.button {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.is-planung-grid {
	background: $grey-bg;

	.left,
	.right {
		display: grid;
		grid-template-rows: max-content;
		gap: 1rem;
		align-content: start;
	}
}
