// fonts
$fa-font-path: "../webfonts/fa";

// Typography
$family-sans-serif: "Allianz Neo", sans-serif;
// $family-serif: "Quando", serif;
// $family-monospace: monospace;
$render-mode: optimizeLegibility;

$size-1: 1.75rem;
$size-2: 1.5rem;
$size-3: 1.25rem;
$size-4: 1.125rem;
$size-5: 15px;
$size-6: 14px;
$size-7: 12px;

$weight-light: 300;
$weight-normal: 400;
$weight-semibold: 600;
$weight-bold: 600;

// Body
$body-background: $white;
$body-size: $size-6;
$body-weight: $weight-normal;

// nav
$navbar-height: 3rem;
$inputHeight: 35px;

$shadow: 0 0 1rem
	rgba(
		$color: $black,
		$alpha: 0.2,
	);

// Responsiveness
$tablet: 769px;
$desktop: 1024px;
$widescreen: 1280px;

// Miscellaneous
$easing: ease-out;
$radius-small: 3px;
$radius: 5px;
$radius-large: 15px;
$speed: 86ms;
