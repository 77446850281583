.dashboard {
	display: grid;
	grid-template-areas: "left right";
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	padding: 1rem;
	background: $light-bg;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;

	.left,
	.right {
		display: grid;
		gap: 1rem;
		grid-auto-flow: row;
		align-content: start;
		padding-bottom: 1rem;
		max-width: 100vw;
	}

	.left {
		grid-area: left;
	}
	.right {
		grid-area: right;
	}

	&.has-bigger-left {
		grid-template-columns: 1fr 0.75fr;
	}

	@include mobile() {
		grid-template-areas: "left" "right";
		grid-template-columns: 1fr !important;
	}
}
