@media print {
	@page {
		// fixes Playwright not setting margins
		margin: 15mm 15mm 15mm 15mm !important;
	}

	.main-menu,
	.agentur-filter-container,
	.burger,
	.modal-footer {
		display: none;
	}

	html,
	body,
	#root,
	.app-container {
		height: 100%;
		font-size: 13px;
	}

	.tag {
		color: $black;
		background: transparent;
	}

	.widget,
	.document-ap {
		border: none !important;
	}

	.document-form {
		&:not(.is-jpg) {
			.html-content {
				border: 0;
			}
		}

		.document-actions,
		.document-uploads,
		.document-tag,
		.document-changes,
		.document-agt,
		.title {
			display: none;
		}
		.document-content {
			.html-content {
				border-radius: $radius;
			}
		}

		.document-intro,
		.ziele-intro {
			color: $text !important;
		}

		.fokusthemen {
			page-break-inside: avoid;

			table {
				td {
					white-space: break-spaces;
				}
				td:last-of-type {
					width: 100%;
					padding-left: 2rem;
				}
			}
		}

		.ziele-list {
			.ziele-list-item {
				display: block;
				background: transparent;

				&:before {
					content: "\25CF";
					padding-right: 0.5rem;
				}
			}
		}
	}

	table {
		tr,
		td,
		th {
			page-break-inside: avoid;
			border: 0 !important;
			box-shadow: none !important;
		}

		tr {
			border-bottom: 2px solid $border !important;
		}
	}

	.print-page-loaded {
		color: transparent;
	}
	.auth-form {
		opacity: 0;
	}
	.app-container.is-auth {
		background: white;
	}
}

.print-header {
	@media screen {
		display: none;
	}

	.allianz-logo {
		height: 30px;

		svg {
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.page-break-avoid {
	page-break-inside: avoid;
}
