.bonistufen-cell {
	.bonistufen-button {
		width: 100%;
		display: block;
		text-align: left;
		background: transparent;
		border: 0;
		outline: none;
		font-size: 1.2rem;
		padding: 0.25rem;
		cursor: pointer;

		&:hover,
		&:focus {
			color: $primary;
		}
	}
}
