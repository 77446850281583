.custom-tooltip {
	background: $white;
	border: 1px solid $border;
	border-radius: $radius;
	box-shadow: 0 0 1rem rgba($color: $black, $alpha: 0.2);
	padding: 0.5rem 1rem;

	.table {
		th {
			background: $light-bg;
			color: $text;
			padding: 0.5rem 0.25rem;
			font-weight: $weight-semibold;
		}
		td {
			padding: 0.25rem;

			&:first-of-type {
				font-weight: $weight-semibold;
			}
		}
	}
}
