.is-themen-widget {
	.themen-list {
		padding-top: 1rem;
		display: grid;
		grid-auto-flow: row;
		gap: 0.5rem;

		.themen-list-item {
			overflow: hidden;

			.is-themen-list-button {
				width: 100%;
				text-transform: none;
				background: $primary;
				color: $white;
				text-align: left;
				display: grid;
				grid-template-columns: max-content 1fr;
				gap: 0.5rem;
				align-items: center;

				.themen-details {
					display: block;
					overflow: hidden;
				}

				.themen-title {
					display: block;
					font-size: $size-6;
					font-weight: $weight-semibold;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
				.themen-count {
					display: block;
					font-size: $size-7;
				}
			}
		}
	}
}
