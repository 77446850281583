.tasks-container {
	.tasks-grid {
		display: grid;

		grid-template-areas:
			"filter create"
			"list list";
		grid-template-columns: 1fr max-content;
		align-items: start;

		@include mobile() {
			grid-template-areas: "create" "filter" "list";
		}

		.documents-filter {
			grid-area: filter;
			margin: 1rem;
		}
		.new-task-button {
			grid-area: create;
			margin: 1rem;
		}
		.table-container {
			grid-area: list;
		}
	}
}

.task-viewer-form {
	.select {
		justify-self: start;
	}

	.task-date {
		display: grid;
		gap: 1rem;
		grid-auto-flow: column;
		align-items: center;
		justify-content: start;
	}

	.fr-box {
		margin-bottom: 1rem;

		.fr-wrapper {
			border-radius: 0 0 $radius $radius;
		}
	}
}

.tasks-list {
	display: grid;
	grid-auto-flow: row;

	.task-list-item {
		display: grid;
		gap: 0.25rem;
		grid-template-areas:
			"check text actions"
			"check thema date";
		grid-template-columns: max-content 1fr 6rem;
		border-radius: $radius;
		border: 1px solid $border;
		border-left: 5px solid $grey-light;
		min-height: 3rem;
		align-items: center;
		padding: 0.5rem;
		padding-left: 0;
		margin-bottom: 0.5rem;

		.check {
			grid-area: check;
			padding: 0.25rem;
		}
		.text {
			grid-area: text;
			font-weight: $weight-semibold;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.thema {
			grid-area: thema;
			color: $text-light;
		}
		.actions {
			grid-area: actions;
			text-align: right;
		}
		.date {
			grid-area: date;
			color: $text-light;
			text-align: right;
			font-size: $size-7;
		}

		&.status-grey {
			.check .button {
				color: $grey-light;
			}
		}
		&.status-red {
			border-left-color: $error;
			.check .button {
				color: $error;
			}
		}
		&.status-yellow {
			border-left-color: $warning;
			.check .button {
				color: $warning;
			}
		}
		&.status-green {
			border-left-color: $success;
			.check .button {
				color: $success;
			}
		}
	}
}
