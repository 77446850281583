// windows high contrast styles

/*
windowText: controls the color of text content.
linkText: controls the color of the hyperlink text.
grayText: controls the color of the disabled text.
highlightText: controls the color of selected text.
highlight: controls the background color of selected text.
buttonText: controls the background color of a <button> element.
buttonFace: controls the color of a <button> element's text.
window: controls the color of the background.
*/

@media screen and (-ms-high-contrast: active) {
	*:focus {
		outline: 3px solid transparent;
	}

	.progress-ring {
		.graphic {
			svg path {
				fill: buttonText;
				&.background {
					fill: buttonFace;
				}
			}
		}
	}

	.recharts-surface {
		.recharts-symbols,
		.recharts-rectangle,
		.recharts-label-list text {
			fill: buttonText;
			opacity: 1;
		}

		.recharts-line {
			path {
				stroke: grayText;
			}
			circle {
				stroke: grayText;
				fill: grayText;
			}
		}
	}
	.recharts-legend-item {
		.recharts-legend-icon {
			fill: windowText;
			stroke: windowText;
		}
	}

	.ze-bar {
		&.is-default,
		&.is-none {
			background: buttonFace;
			border: 1px solid buttonText;

			.value {
				background: buttonText;
			}
		}
	}

	.modal {
		border: 2px solid buttonText;
	}

	[data-tooltip] {
		&:hover,
		&:focus {
			&:after {
				opacity: 0;
			}
		}
	}

	//froala
	.fr-box {
		.fr-toolbar {
			.fr-btn {
				background: buttonFace !important;
				border: 1px solid buttonText !important;
				color: buttonText !important;

				.fr-svg {
					path {
						fill: buttonText !important;
					}
				}

				&.fr-active {
					border-color: highlight !important;

					.fr-svg {
						path {
							fill: highlight !important;
						}
					}
				}
			}
		}
	}

	.achievements-icon {
		.icon {
			path {
				fill: buttonFace;
				stroke: buttonText;
			}
			rect,
			circle {
				stroke: buttonFace;
				fill: buttonText;
			}

			&.gsb-icon {
				path {
					fill: transparent;
				}
			}
		}
	}

	.leadnow-icon {
		path {
			fill: buttonText;
			stroke: none;
		}
	}
}
