.button-group {
	.button {
		border-radius: 0;
		margin-left: -1px;

		&:first-of-type {
			margin-left: 0;
			border-radius: $radius 0 0 $radius;
		}
		&:last-of-type {
			border-radius: 0 $radius $radius 0;
		}

		&.is-inverted {
			&.is-active {
				background: $primary;
				color: $white;
			}
		}
	}

	&.is-full-width {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
		width: 100%;
	}

	&.is-vertical {
		display: grid;

		.button {
			margin-left: 0;
			margin-top: -1px;

			&:first-of-type {
				margin-top: 0;
				border-radius: $radius $radius 0 0;
			}
			&:last-of-type {
				border-radius: 0 0 $radius $radius;
			}
		}
	}

	&.is-radio-button-group {
		.button {
			border-radius: 0;
			&:disabled {
				pointer-events: none;
			}

			&:first-of-type {
				border-radius: $radius 0 0 $radius;
			}
			&:last-of-type {
				border-radius: 0 $radius $radius 0;
			}
		}
		&:not(.is-checklist):not(.is-vertical) {
			.button {
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;

				&.is-active {
					background: $primary;
					border-color: $primary;
					color: $white;
				}
			}
		}
		&.is-small {
			.button {
				font-size: $size-7;
				padding-top: 0.25rem;
				padding-bottom: 0.25rem;
			}
		}

		&.is-vertical {
			.button {
				display: block;
				background: transparent;
				box-shadow: none;
				position: relative;
				text-align: left;
				text-indent: 1.5rem;
				padding: 0.25rem 0;

				&:before {
					content: "";
					position: absolute;
					left: 0;
					display: block;
					width: 1rem;
					height: 1rem;
					border-radius: 1rem;
					box-shadow: inset 0 0 0 1px $primary;
				}
				&:disabled {
					color: $grey-light;
					&:before {
						box-shadow: inset 0 0 0 1px $grey-light;
					}
				}
				&.is-active {
					color: $primary;
					&:before {
						background: $primary;
					}
				}
			}
		}
	}
}
