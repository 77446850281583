.is-branchenplanung-widget {
	.bp-toggle {
		display: grid;
		gap: 0.5rem;
		grid-auto-flow: column;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
		border-bottom: 1px solid $border;
	}

	.bp-single-edit {
		input {
			min-width: 50px;
		}
	}
}

.anwendungen-panel .body {
	.bp-single-edit {
		display: grid;

		.bp-unit-switch {
			position: relative;
			margin-top: 0;
			float: right;
		}
	}
}
