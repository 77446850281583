.app-container.is-auth {
	background: url("/assets/images/bg.jpg");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	display: block;

	.app-content {
		display: grid;
		width: 100vw;
		height: 100vh;
		align-content: center;
		justify-content: center;
	}
}

.auth-form {
	display: grid;
	gap: 2rem;
	justify-self: right;
	justify-content: center;
	align-content: center;
	margin: 4rem 10rem;
	padding: 1rem;
	grid-template-columns: 1fr;
	background: $white;
	border-radius: $radius;
	box-shadow: 0 0 1rem rgba($color: $black, $alpha: 0.25);

	min-width: 420px;
	max-width: 520px;

	@include mobile() {
		min-width: auto;
		max-width: 520px;
		width: 95vw;
	}

	.auth-form-header {
		text-align: center;
		.title {
			font-weight: $weight-normal;
			font-size: 2rem;
			margin-top: 2.5rem;
			margin-bottom: 2.5rem;
		}
	}
}

button.button.reveal-pw-button {
	position: absolute;
	right: 0;
	bottom: 0;
	height: $inputHeight;
	width: $inputHeight;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		cursor: pointer !important;
	}

	.fa {
		margin-top: 3px;
		pointer-events: none;
	}
}
input::-ms-reveal {
	display: none;
}
