@keyframes panel-in {
	0% {
		opacity: 0;
		transform: translateX(50px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.side-panel {
	position: fixed;
	top: 0;
	right: 0;
	width: 75vw;
	height: 100vh;

	display: grid;
	grid-template-rows: max-content 1fr max-content;

	background: $white;
	box-shadow: 0 0 2rem rgba($color: $black, $alpha: 0.5);

	animation: panel-in 0.1s ease-out;
	transition: width 0.2s ease-in-out;

	z-index: 100;

	@include mobile() {
		width: 100vw;
	}

	.side-panel {
		width: 60vw;
		min-width: 760px;
		box-shadow: 0 0 2rem rgba($color: $black, $alpha: 0.75);

		@include mobile() {
			width: 100vw;
			min-width: auto;
		}

		.body {
			overflow-y: auto;
		}
	}

	&.is-wide {
		width: 88vw;

		.side-panel.is-wide {
			width: 75vw;
		}
	}

	& > .header {
		height: $navbar-height;
		border-bottom: 1px solid $border;
		display: grid;
		gap: 3rem;
		grid-template-columns: max-content 1fr;
		align-items: center;
		background: $white;

		@include mobile() {
			grid-template-columns: 1fr;
			grid-auto-flow: row;
			gap: 0.5rem;
			height: auto;
		}

		.header-left {
			display: grid;
			grid-template-columns: min-content 1fr;
			align-items: center;
		}

		.header-right {
			justify-self: end;
			padding-right: 1rem;

			@include mobile() {
				border-top: 1px solid $border;
				overflow-x: auto;
				width: 100%;
			}

			nav.side-panel-nav {
				display: grid;
				grid-auto-flow: column;
				height: $navbar-height;
				padding: 0 0.5rem;

				a {
					display: inline-block;
					height: 100%;
					color: $text;
					text-decoration: none;
					padding: 0.25rem 0.5rem;
					display: flex;
					align-items: center;

					&:hover,
					&:focus {
						color: $action;
					}

					&.is-active {
						color: $primary;
						box-shadow: inset 0 -3px 0 $primary;
					}
				}
			}
		}

		.button.is-close {
			width: $navbar-height;
			color: $primary;
		}

		.title-area {
			margin-left: 0.5rem;
			min-width: 180px;

			@include mobile() {
				padding-top: 0.5rem;
			}

			.title {
				font-weight: $weight-semibold;
			}
			.subtitle {
				color: $grey;
			}
		}

		.button.is-header-button {
			padding: 0.5rem;
			background: $white;
			color: $primary;
			text-align: center;
			height: calc(#{$navbar-height} - 0.5rem);
			min-width: calc(#{$navbar-height} - 0.5rem);

			&.is-grey {
				color: $grey-light;
			}

			&:hover,
			&:focus {
				background: $soft-sky;
			}
		}
	}

	& > .body {
		overflow-x: hidden;
		overflow-y: auto;
		display: block;
	}

	& > .footer {
		background: $white;
		border-top: 1px solid $border;
		padding: 1rem;
	}

	.panel-navigation {
		border-bottom: 1px solid $border;

		.button {
			box-shadow: none !important;
			color: $action;

			&:hover,
			&:focus {
				color: $action !important;
			}
		}
	}

	&.is-light {
		.body {
			background: $light-bg;
		}
	}
	&.is-padded {
		.body {
			padding: 1rem;
		}
	}

	&.is-fullscreen {
		position: fixed;
		width: 100%;

		.side-panel {
			width: 80vw;
			min-width: 760px;
		}
	}
}
