.widget {
	background: $white;
	border: 1px solid $border;
	border-radius: $radius;
	overflow-x: hidden;
	min-width: 350px;

	@include mobile {
		min-width: auto;
	}

	& > .header {
		border-bottom: 1px solid $border;
		display: grid;
		grid-template-columns: max-content 1fr max-content;
		gap: 1rem;
		padding: 0.5rem;
		align-items: center;
		color: $text-grey;
		min-height: 50px;

		.title {
			font-size: $size-5;
		}
	}

	&.no-collapse {
		& > .header {
			grid-template-columns: 1fr max-content;
			padding-left: 1rem;
		}
	}

	& > .body {
		padding: 1rem;
		overflow-x: auto;
	}

	&.is-paddingless {
		.body {
			padding: 0;
		}
	}

	.title-actions {
		.button.is-widget-header {
			box-shadow: none;
			color: $primary;
			background: transparent;
			font-weight: $weight-semibold;
		}
	}

	.child-widgets {
		margin: -1rem;
	}

	&.is-child-widget {
		border: 0;
		border-radius: 0;

		&:not(:first-child) {
			border-top: 1px solid $border;
		}

		& > .header {
			grid-template-columns: 1fr max-content;
			padding-left: 1rem;
		}
	}

	&.is-highlight {
		border-color: $rich-turquoise;
		// box-shadow: 0 0 2px $rich-turquoise;

		& > .header {
			border-color: $rich-turquoise;
		}
	}
}

// table sticky override
.widget {
	.table {
		th,
		td {
			position: initial !important;
		}
	}
}

.action-controls {
	.is-widget-edit-button {
		height: 100%;
	}
	.widget-actions {
		border-bottom: 1px solid $border;
		height: 100%;
		background: $white;
		padding: 0 0.5rem;
		display: grid;
		grid-auto-flow: column;
		align-content: center;
		justify-content: center;
		border-left: 1px solid $border;
		border-bottom: 1px solid $border;
	}
}

.widget-edit-buttons {
	border-radius: $radius $radius 0 0;
	background: $white;
	border: 1px solid $border;
	padding: 0.25rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	text-align: right;

	& ~ .widget {
		opacity: 0.75;
		pointer-events: none;
	}

	&.widget-hidden {
		& ~ .widget {
			opacity: 0.25;
			pointer-events: none;
		}
	}
}
