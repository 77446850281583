.table-container {
	max-width: 100%;
	max-height: 100%;
	border-top: 1px solid $border;
	top: 0;

	overflow-x: auto;

	.table-container {
		border-top: 0;
		display: unset;
	}

	& + .table-container {
		border-top: 0;
		padding-top: 1rem;
	}

	.table-edit-header {
		padding: 0.5rem 1rem;
		border-top: 1px solid $border;
		border-bottom: 1px solid $border;
		text-align: right;
	}

	.detached-table-edit-footer {
		position: sticky;
		left: 0;
		bottom: 0;
		background-color: $white;
		z-index: 100;

		.table-edit-footer-buttons {
			text-align: right;
		}
	}
}

.table {
	width: 100%;
	position: relative;

	thead {
		color: $grey;

		tr {
			th {
				font-weight: $weight-semibold;
				padding: 1rem 0.5rem;
				background: $white;
				box-shadow: inset 0 -1px 0 $border;

				&:first-of-type:not(.is-graphic-cell) {
					padding-left: 1rem;
					padding-left: 1rem;
					position: sticky;
					left: 0;
					z-index: 3;
				}
				&:last-of-type {
					padding-right: 1rem;
				}

				&.is-sorting {
					color: $primary;
					font-weight: $weight-bold;
				}

				.table-sort-button {
					border: 0;
					background: transparent;
					color: $primary;
					padding: 0;
					cursor: pointer;
					display: inline-grid;
					gap: 0.5rem;
					grid-template-columns: 1fr max-content;
					align-items: center;
					text-align: left;

					&:hover,
					&:focus {
						color: $primary-hover;
					}
				}
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px solid $border;
			cursor: pointer;
			outline: none;

			&:hover,
			&:focus {
				background: $almost-white;
			}

			&:focus {
				td {
					background: lighten($primary, 50%);
				}
			}

			&.is-active {
				td {
					background: lighten($primary, 50%);
				}
			}

			&.is-group-header {
				td {
					background: $almost-white;
				}
			}
		}

		td {
			padding: 0.75rem 0.5rem;
			background: $white;
			white-space: nowrap;
			max-width: 175px;
			overflow: hidden;
			text-overflow: ellipsis;

			&:first-of-type:not(.is-graphic-cell) {
				padding-left: 1rem;
				position: sticky;
				left: 0;
				z-index: 2;
				max-width: 250px;
			}
			&:last-of-type {
				padding-right: 1rem;
			}
		}
	}

	tfoot {
		td {
			padding-bottom: 1rem;
			padding: 0.5rem;
			background: $white;
			vertical-align: top;

			&:first-of-type {
				padding-left: 1rem;
				position: sticky;
				left: 0;
				z-index: 4;
			}
		}

		.table-edit-footer {
			td {
				text-align: right;

				@include mobile() {
					text-align: left;
				}
			}
		}
	}

	&.has-sticky-header {
		thead {
			tr {
				th {
					position: sticky;
					top: 0;
					z-index: 5;

					&:first-of-type {
						z-index: 6;
					}
				}
			}
		}
	}

	&.has-sticky-footer {
		tbody {
			&:after {
				// create a bit of an extra gap
				content: "";
				display: block;
				height: 2rem;
			}
		}

		tfoot {
			position: sticky;
			bottom: 0;
			border-top: 2px solid transparent;
			z-index: 3;
			box-shadow: inset 0 0 0 1px $grey-lighter; // needed to create a border on the sticky footer
			background: $white;

			@include mobile() {
				position: initial;
			}

			tr {
				td {
					background: $white;
				}
			}
		}

		.table-edit-footer {
			@media screen and (max-height: 750px) {
				display: none;
			}
		}
	}

	&.is-text {
		tbody {
			tr {
				border: 0;
				cursor: initial;

				&:hover,
				&:focus {
					background: transparent;
				}

				&:first-of-type {
					td {
						padding-top: 1rem;
					}
				}
				&:last-of-type {
					td {
						padding-bottom: 1rem;
					}
				}
			}
			td {
				padding-top: 0.25rem;
				padding-bottom: 0.25rem;
			}
		}
	}

	&.is-striped {
		tbody {
			tr {
				&:nth-child(even) {
					background: $almost-white;
				}
			}
		}
	}

	&.align-top {
		thead {
			vertical-align: bottom;
		}
		tbody {
			vertical-align: top;
		}
	}

	&.is-compact {
		thead {
			tr {
				th {
					padding-top: 0.5rem;
					padding-bottom: 0.5rem;
				}
			}
		}
	}
	&.no-hover {
		tbody {
			tr {
				cursor: initial;
				&:hover,
				&:focus {
					background: transparent;
				}
			}
		}
	}
	&.has-side-border {
		tr {
			border-left: 1px solid $border;
			border-right: 1px solid $border;
		}
	}

	&.fill-first-col {
		td:first-of-type {
			width: 100%;
		}
	}

	&.fill-last-col {
		td:last-of-type {
			width: 100%;
		}
	}

	&.left-col-bold {
		td:first-of-type {
			font-weight: $weight-semibold;
		}
	}

	&.left-col-half {
		td:first-of-type {
			width: 50%;
		}
	}

	&.last-col-right {
		td:last-of-type {
			text-align: right;
		}
	}
	&.allow-wrap {
		td {
			white-space: initial;
		}
	}

	// table cell/header modifiers
	td,
	th {
		// borders
		&.border-left-blue {
			border-left: 2px solid $primary;
		}
		&.border-left-green {
			border-left: 2px solid $green;
		}
		&.border-left-yellow {
			border-left: 2px solid $yellow;
		}
		&.border-left-turquoise {
			border-left: 2px solid $turquoise;
		}
		// background
		&.bg-blue {
			background: $blue-bg;
		}
		&.bg-green {
			background: $green-bg;
		}
		&.bg-yellow {
			background: $yellow-bg;
		}
		&.bg-turquoise {
			background: $turquoise-bg;
		}
		&.bg-grey {
			background: $grey-bg;
		}

		&.is-graphic-cell {
			width: 0%;
			padding: 0;
			position: relative !important;

			.color-bar {
				position: absolute;
				top: 0;
				bottom: 0;
				width: 100%;
				background: $border;
			}
		}
	}
}

.table-legend {
	padding: 1rem;
}

.aggregations {
	display: grid;
	grid-auto-flow: row;
}
.aggregation-value {
	display: grid;
	grid-template-columns: max-content 1fr;
	align-items: center;
	white-space: nowrap;
	font-weight: $weight-semibold;

	.agg-icon {
		margin-right: 0.5rem;
		display: block;

		svg {
			display: block;
		}
	}
	.agg-value {
		display: block;
	}

	& + .aggregation-value {
		padding-top: 0.25rem;
	}

	&.is-centered {
		justify-self: center;
	}
}

caption {
	border-bottom: 1px solid $border;
}

.csv-download-link {
	padding: 0.25rem 1rem;
	margin: 0.25rem 1rem;
	text-decoration: none;
	color: $primary;
	border: 1px solid $primary;
	border-radius: $radius-small;
	display: inline-block;
}

.visible-observer {
	display: block;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 150px;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 255) 50%);
	z-index: 9999;
	display: grid;
	padding: 1rem;
	align-items: end;
	justify-items: start;
}
