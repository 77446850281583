.react-select-container {
	// @extend .select;

	.react-select {
		&__control {
			@extend .inputbase;
			display: flex;
			padding: 0;
			min-height: $inputHeight;
			height: auto;
		}
		&__input-container {
			padding: 0;
			margin: 0;
		}
		&__value-container {
			padding: 0 0 0 0.5rem;
			margin: 0;
			margin-top: -1px; // center align with border

			&--is-multi {
				padding-top: 3px;
			}
		}
		&__single-value {
			color: $black;
		}

		&__placeholder {
			color: $placeholder;
		}
		&__indicator-separator {
			margin: 0;
			background: $border;
		}
		&__indicator {
			padding: 0 0.5rem;
		}
		&__input {
			// overwriting base input classes
			padding: 0.5rem 0 !important;
			height: auto !important;
			min-width: 75px !important;
		}
		&__menu {
			z-index: 100;
		}
		&__option {
			cursor: pointer;
			&--is-selected {
				background: transparent;
				color: $grey-light;

				&:hover {
					color: $text;
					background: $grey-lighter;
				}
			}
		}
	}

	&.has-value {
		.react-select {
			&__control,
			&__indicator-separator {
				border-color: $info;
				background: rgba($color: $info, $alpha: 0.2);
			}
			&__indicator {
				color: $text-grey;
			}
		}
	}
}

// allow the dropdown menu to break out of modal
.modal,
.modal-body {
	&:has(.react-select__menu) {
		overflow: visible !important;
	}
}
