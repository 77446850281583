// Primary palette
$brand: #004a93;
$black: #3c3c3c;
$grey: #646363;
$grey-light: lighten($grey, 35);
$grey-lighter: lighten($grey, 50);
$grey-bg: lighten($grey, 58);
$white: #ffffff;
$almost-white: #f5f6f6;

// Digital colors
$text: #000000;
$text-grey: #414141;
$text-light: lighten($text-grey, 20);

// Bright Action Colour (#007AB3) is used for solid buttons, lines of clickable elements, bold action background areas and clickable icons with text labels.
$primary: #007ab3;
// Dark Action Colour (#006192) is used for other elements like linked text and clickable elements such as icons that convey vital information not shown with text, for example, the X icon used to close flyouts & modals.
$action: #006192;

$primary-hover: darken($primary, 15);

// Notification colors
$error: #dc3149;
$warning: #f5a734;
$success: #1e8927;
// $info: #496ebd;
$info: #6bbec3;

$blue: #0077c8;
$dark-blue: #113781;
$yellow: #f2a900;
$dark-yellow: #cd8300;
$orange: #e15200;
$green: $success;
$dark-green: #4c8405;
$red: #e12047;
$rich-red: #e02f38;
$brown: #916836;
$turquoise: #158390;
$purple: #7c6394;

// Used colors
$vibrant-blue: #96dcfa;
$vibrant-orange: #ff934f;
$vibrant-lemon: #ffe8b0;
$vibrant-turquoise: #7fe4e0;
$vibrant-yellow: #fcd25b;
$vibrant-pink: #f7c7c3;
$vibrant-red: #e4003a;
$vibrant-purple: $purple;
$vibrant-green: #ccdd61;
$rich-water: #b1dadd;
$rich-turquoise: #007d8c;
$rich-red: #b71e3f;
$rich-purple: #5a3982;
$rich-blue: #49648c;
$rich-green: #407d71;
$rich-burnt: #5b5d30;
$rich-brown: #5a5360;
$soft-sky: #cfe9ee;
$soft-lime: #c0ddbd;
$soft-stone: #d4cdcd;
$soft-grey: #eaeaea;

$yellow-bg: #faf7ef;
$blue-bg: lighten($soft-sky, 7%);
$green-bg: lighten($soft-lime, 15%);
$primary-bg: lighten($primary, 60%);
$turquoise-bg: #e1f2f4;

// @allianz_color_lines: #eaeaea;
// @allianz_color_status: #147ca9;
// @allianz_color_links: #0077c8;
// @allianz_color_error: #e02e37;
// @allianz_color_success: #4d8406;
// @allianz_color_warning: #f2a900;

// colors picked from allianz website...
.x {
	// VBL
	background: #006192;
	background: #96dcfa;
	background: #158390;
	background: #7c6394;
	background: #e15200;
	background: #e12046;
	background: #b98347;
	background: #5a5360;
	background: #fc934f;
	background: #fcd25b;
	background: #ec91a3;

	background: #7fe4e0;
	background: #6bbec3;
	background: #007d8c;
	background: #407d71;
	background: #147d8c;
	background: #8a679c;
	background: #84699e;
	background: #c99859;
	background: #c3d8d4;
	background: #eff6ee;
	background: #113781;
}

// secondary colors
/*
$vibrant-peach: #f7caab;
$vibrant-orange: #ff934f;
$vibrant-lemon: #ffe8b0;
$vibrant-yellow: #fdd25c;
$vibrant-water: #c3e8e7;
$vibrant-turquoise: #7fe4e0;
$vibrant-pink: #f7c7c3;
$vibrant-red: #e4003a;
$vibrant-lavender: #e1cfeb;
$vibrant-purple: #8a679c;
$vibrant-sky: #c1ebfb;
$vibrant-blue: #96dcfa;
$vibrant-lime: #e3ebaf;
$vibrant-green: #ccdd61;

$rich-peach: #f1c8d0;
$rich-water: #b1dadd;
$rich-turquoise: #007d8c;
$rich-red: #b71e3f;
$rich-lavender: #dad0e1;
$rich-purple: #5a3982;
$rich-sky: #cad4de;
$rich-blue: #49648c;
$rich-lime: #d4d5c8;
$rich-green: #407d71;
$rich-lake: #c3d8d4;
$rich-burnt: #5b5d30;
$rich-stone: #d7d3d9;
$rich-brown: #5a5360;

$soft-blossom: #f6e5ea;
$soft-pink: #eeccd5;
$soft-yellow: #ebe1bf;
$soft-sky: #cfe9ee;
$soft-burnt: #eacfc0;
$soft-lime: #c0ddbd;
$soft-stone: #d4cdcd;
$soft-sand: #efe8e6;
$soft-cloud: #e6f4f6;
$soft-grey: #f2f2f0;

$yellow-bg: #faf7ef;
*/

// these all look the same on screen...
$soft-xxx: #f5f3ed;
$soft-xxx: #ebe7db;
$soft-xxx: #dbd3bd;
$soft-xxx: #fbf2f4;
$soft-xxx: #f1f9fa;
$soft-xxx: #f5f0e0;
$soft-xxx: #f9f2ef;
$soft-xxx: #f3e5df;
$soft-xxx: #eff6ee;
$soft-xxx: #dfeede;

//

$body-color: $text;
$body-background-color: $white;

$light-bg: #f4f4f4;
$border: $soft-grey;
$placeholder: $grey-light;

// Document colors
$color-ev: $orange;
$color-upload: $rich-water;
$color-an: $vibrant-yellow;
$color-ap: $warning;
$color-jpg: $rich-purple;
$color-kbvr: $rich-blue;
$color-jpg: $rich-green;
$color-notiz: $info;

// Backgrounds
// $light-bg: #f7f8fb;
// $dark-bg: $grey;

$colors: (
	"white": $white,
	"orange": $orange,
	"yellow": $yellow,
	"green": $green,
	"turquoise": $turquoise,
	"blue": $blue,
	"purple": $purple,
	"red": $red,
	"primary": $primary,
	"grey": $grey,
	"success": $success,
	"warning": $warning,
	"error": $error,
);

@each $name, $color in $colors {
	.color-#{$name} {
		color: $color !important;
	}
	.bg-color-#{$name} {
		background: $color !important;
	}
}
