.note-box {
	border: 1px solid $primary;
	padding: 1rem;
	border-radius: $radius;

	display: grid;
	grid-template-columns: max-content 1fr;
	gap: 1rem;
	white-space: initial;

	&.align-center {
		align-items: center;

		.note-icon {
			align-self: center;
		}
	}

	&.is-red {
		border-color: $red;
	}

	&.has-margin {
		margin: 0.25rem 1rem;
	}
}
