@keyframes width-from-zero {
	0% {
		width: 0%;
	}
	100% {
	}
}

.ze-bar {
	//layout
	position: relative;
	height: 2rem;
	margin: 2rem 0;
	border-radius: $radius;
	background: $grey-lighter;

	.value,
	.target,
	.over {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		border-radius: $radius;
		animation: width-from-zero 0.5s ease-out;
		transition: width 0.5s ease-in-out;

		span {
			position: absolute;
			left: 0;
			right: 0;
			top: -1.35rem;
			white-space: nowrap;
			text-align: right;
		}
	}

	.target {
		span {
			bottom: -1.35rem;
			top: auto;
		}
	}

	.value {
		background: transparent;
	}

	.over {
		left: auto;
		right: 0;
	}

	// colors
	&.is-default {
		background: rgba($yellow, 0.25);

		.value {
			background: $yellow;
		}
	}

	&.is-over {
		background: rgba($color: $success, $alpha: 0.7);

		.over {
			background: $success;
		}
	}
}
