.grid {
	display: grid;
	gap: 1rem;
	align-items: flex-start;

	&.is-gapless {
		gap: 0;
	}

	&.is-col-auto {
		grid-auto-flow: column;

		&.is-responsive {
			@include mobile() {
				grid-auto-flow: row;
			}
		}
	}

	&.is-col-half {
		grid-template-columns: 1fr 1fr;
	}
	&.is-col-bigger-left {
		grid-template-columns: 1fr 0.75fr;
	}
	&.is-col-bigger-right {
		grid-template-columns: 0.75fr 1fr;
	}

	&.is-col-half,
	&.is-col-bigger-left,
	&.is-col-bigger-right {
		@include mobile() {
			grid-template-columns: 1fr;
		}
	}

	&.is-col-half-mobile {
		@include mobile() {
			grid-template-columns: 1fr 1fr;
		}
	}

	&.align-center {
		align-items: center;
	}
	&.align-start {
		align-items: center;
	}
	&.align-stretch {
		align-items: stretch;
	}
	&.justify-start {
		justify-content: start;
	}
	&.justify-between {
		justify-content: space-between;
	}
}
