.popover {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	margin-left: 10px;

	background: $white;
	padding: 1rem;
	border-radius: $radius;
	border: 1px solid $primary;
	color: $text;
	box-shadow: 0 0 1rem rgba($color: $black, $alpha: 0.25);

	text-transform: none;
	max-width: 25rem;
	width: max-content;

	animation: tooltip-in 0.15s ease-out;

	.title {
		font-weight: $weight-bold;
	}

	.text {
		padding: 1rem 0;
	}

	.actions {
		text-align: right;
		margin-top: 0.5rem;
	}

	// arrow
	&:after {
		content: "";
		position: absolute;
		width: 14px;
		height: 16px;
		left: -13px;
		top: 18px;
		transform: translateY(-50%);
		background: url("/assets/images/tooltip-arrow.svg");
		animation: tooltip-in 0.15s ease-out;
	}
}

.popover-overlay {
	z-index: 99998;
	position: fixed;
	content: "";
	top: 0;
	width: 100vw;
	height: 100vh;
	background: rgba($color: $black, $alpha: 0.35);
}
