.custom-legend {
	text-align: center;

	.legend-item {
		display: inline-grid;
		grid-template-columns: max-content 1fr;
		align-items: center;
		gap: 0.25rem;
		padding: 0.25rem 0.5rem;

		.legend-icon {
			display: inline-block;
			width: 1rem;
			height: 1rem;
			border: 1px solid $black;
		}
	}
}
