.dotted-tag {
	display: inline-grid;
	grid-auto-flow: column;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;

	height: 22px;
	min-width: 22px;

	box-shadow: inset 0 0 0 1px $grey-light;
	border-radius: $radius-small;
	margin-right: 0.25rem;
	padding: 0 0.35rem;
	background: $white;

	.dot {
		display: inline-block;
		width: 10px;
		height: 10px;
		background: $grey-light;
		border-radius: 2px;
		box-shadow: inset 0 0 0 1px rgba(black, 0.2);
	}

	&.is-primary {
		box-shadow: inset 0 0 0 1px $primary;
		.dot {
			background: $primary;
		}
	}
	&.is-green {
		box-shadow: inset 0 0 0 1px $green;
		.dot {
			background: $green;
		}
	}
	&.is-yellow {
		box-shadow: inset 0 0 0 1px $yellow;
		.dot {
			background: $yellow;
		}
	}
	&.is-purple {
		box-shadow: inset 0 0 0 1px $purple;
		.dot {
			background: $purple;
		}
	}
	&.is-red {
		box-shadow: inset 0 0 0 1px $red;
		.dot {
			background: $red;
		}
	}
	&.is-turquoise {
		box-shadow: inset 0 0 0 1px $turquoise;
		.dot {
			background: $turquoise;
		}
	}

	&.is-compact {
		box-shadow: none;
		padding: 0;
	}
}

.dotted-tag-list {
	max-width: 100%;
	white-space: normal;

	.dotted-tag {
		margin-bottom: 0.5rem;
		margin-right: 0.5rem;
	}
}

.dotted-tag-vertical-spacer {
	display: block;
	height: 22px;
}
