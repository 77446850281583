@mixin mobile() {
	@media screen and (max-width: $tablet) {
		@content;
	}
}

@mixin tablet() {
	@media screen and (max-width: $desktop) {
		@content;
	}
}

@mixin tabletAndBigger() {
	@media screen and (min-width: $tablet) {
		@content;
	}
}

@mixin desktop() {
	@media screen and (min-width: $desktop) {
		@content;
	}
}

@mixin border-shadow($color: $black, $opacity: 0.13, $thickness: 1px) {
	box-shadow: inset 0 0 0 $thickness rgba($color: $color, $alpha: $opacity);
}
