html,
body,
#root {
	height: 100vh;
}

.section {
	padding: 1rem;

	.title {
		margin-bottom: 1rem;
	}
}

.container {
	position: relative;
	max-width: $widescreen;
	width: 100%;
	margin: 0 auto;

	&.is-narrow {
		max-width: $tablet;
	}

	&.is-medium {
		max-width: $desktop;
	}

	& + .container {
		padding-top: 2rem;
	}

	&.is-marginless {
		margin: 0;
	}
}
