.anwendungen-panel {
	.table-container {
		overflow-y: initial;
	}

	.body {
		display: grid;
		grid-template-rows: max-content auto;
		grid-template-columns: minmax(0, 1fr);

		.bp-unit-switch {
			// position: absolute;
			// z-index: 10;
			// right: 1rem;
			// margin-top: -2rem;
		}

		.panel-body {
			overflow: auto;

			.panel-body-header {
				padding: 0.25rem 1rem;
				text-align: right;
				display: grid;
				justify-content: end;
			}

			.panel-fake-footer {
				padding: 1rem;
				border-top: 1px solid $border;
			}
		}
	}
}
