.is-paddingless {
	padding: 0 !important;
}
.is-marginless {
	margin: 0 !important;
}
.is-left,
.has-text-left {
	text-align: left !important;
}
.is-centered,
.has-text-centered {
	text-align: center !important;
}
.is-right,
.has-text-right {
	text-align: right !important;
}
.align-items-start {
	align-items: start !important;
}
.full-width {
	width: 100%;
}
.border-bottom {
	border-bottom: 1px solid $border;
}
.border-top {
	border-top: 1px solid $border;
}

.whitespace-wrap {
	white-space: normal;
}

// font weights
.is-bold {
	font-weight: $weight-bold;
}

// medal colors
.medal {
	color: $grey-lighter;

	&.is-large {
		font-size: 1.5rem;
	}

	&.is-hidden {
		color: transparent;
	}
	&.is-bronze {
		color: #b98b16;
	}
	&.is-silber {
		color: #9fb6cd;
	}
	&.is-gold {
		color: #f3d111;
	}
}

// define colors for specific documents
.color-ev {
	background: $color-ev;
}
.color-upload {
	background: $color-upload;
}
.color-an {
	background: $color-an;
}
.color-ap {
	background: $color-ap;
}
.color-jpg {
	background: $color-jpg;
	color: $white;
}
.color-kbvr {
	background: $color-kbvr;
	color: $white;
}
.color-jpg {
	background: $color-jpg;
	color: $white;
}
.color-notiz,
.color-user_notiz {
	background: $color-notiz;
	color: $white;
}

// define colors for specific roles
.color-KME {
	background: $vibrant-purple;
	color: $white;
}
.color-KVS {
	background: $orange;
	color: $white;
}
.color-VAS {
	background: $red;
	color: $white;
}

.color-BBS {
	background: $vibrant-blue;
	color: $text;
}
.color-UB {
	background: $rich-brown;
	color: $white;
}
.color-LPV {
	background: $turquoise;
	color: $text;
}
.color-VBL {
	background: $action;
	color: $white;
}
.color-GSL {
	background: $rich-blue;
	color: $white;
}
.color-BSG {
	background: $vibrant-yellow;
	color: $text;
}
.color-EDT {
	background: $rich-green;
	color: $white;
}
.color-EDP {
	background: $brown;
	color: $white;
}
.color-LDP {
	background: $vibrant-yellow;
	color: $text;
}

.comma-list {
	.comma-list-item {
		&:not(:last-of-type) {
			&:after {
				content: ",";
			}
		}
	}
}

.red-border {
	border: 5px solid red;
}
