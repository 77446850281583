.ziele-section,
.search-section {
	display: grid;
	gap: 1rem;
	grid-template-columns: max-content 1fr;
	border-bottom: 1px solid $border;
	padding-bottom: 1rem;
	margin-bottom: 1rem;

	.label {
		font-weight: $weight-semibold;
		padding-top: 0.2rem;
		min-width: 60px;
	}

	.ziele-list {
		padding-top: 0;

		.ziele-list-item {
			font-size: $size-7;
		}
	}
}

.search-section {
	align-items: center;
	border-bottom: 0;
	padding-bottom: 0;

	input[type="text"] {
		max-width: 300px !important;
	}
}

.themen-planung-header {
	padding-bottom: 1rem;

	.label {
		font-weight: $weight-semibold;
		padding-bottom: 0.5rem;
	}

	.ziele-list {
		padding-top: 0;
	}
}

.themen-auswahl {
	display: grid;
	grid-template-columns: 5fr 2fr;
	gap: 1rem;

	.themen,
	.massnahmen {
		border: 1px solid $border;
		border-radius: $radius;
		overflow: hidden;
	}

	.themen {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}

	.themen-col {
		display: grid;
		grid-template-rows: max-content 1fr;

		.header {
			padding: 1rem;
			font-weight: $weight-semibold;
		}
		.themen-list {
			padding: 1rem;
			border-top: 1px solid $border;
			border-right: 1px solid $border;

			display: grid;
			gap: 0.5rem;
			align-content: start;
			overflow-x: hidden;

			.themen-list-item {
				position: relative;
				display: grid;
				gap: 0.5rem;
				grid-template-columns: 1fr max-content max-content max-content;
				align-items: center;
				padding: 0 1rem;
				overflow: hidden;

				&.is-active {
					background: $border;
					border-radius: $radius;
				}

				.fa-chevron-right,
				.fa-plus-circle {
					color: $primary;
				}

				.button {
					background: transparent;
					box-shadow: none;
					text-align: left;
					text-transform: none;
					overflow: hidden;
					padding-right: 0;
					padding-left: 0;

					span {
						text-overflow: ellipsis;
						display: block;
						overflow: hidden;
					}

					.fa-icon {
						color: $primary;
					}

					&:hover,
					&:focus {
						color: $primary;
					}

					&.is-icon {
						display: flex;
						padding: 1px;
						font-size: $size-6;
					}
				}

				&.has-checkbox {
					grid-template-columns: max-content 1fr max-content max-content;
				}

				&.add-custom-item {
					display: block;

					.button {
						width: 100%;
						display: grid;
						align-items: center;
						justify-content: space-between;
						grid-template-columns: 1fr max-content;
					}
				}
			}

			.tasks-list {
				margin-top: 0;
			}
		}

		&:last-of-type {
			.themen-list {
				border-right: 0;
			}
		}
	}

	&.has-active-search {
		.themen-list {
			.themen-list-item {
				.button {
					opacity: 0.5;
				}
				.highlight-icon {
					position: absolute;
					color: $primary;
					font-size: $size-7;
					top: 50%;
					left: 0.5rem;
					transform: translateY(-50%);
					z-index: 10;

					& + .button {
						opacity: 1;
						text-indent: 1rem;
						color: $action;
					}
				}
			}
		}
	}
}
