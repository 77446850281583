.progress-ring {
	display: inline-grid;
	align-items: center;
	justify-content: center;
	padding: 1rem 0;

	.graphic {
		position: relative;

		.recharts-responsive-container {
			margin: 0 auto;
		}

		.text {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;

			transform: translateY(-50%);
			text-align: center;
			font-size: 2rem;
		}
	}

	.label {
		text-align: center;
		font-weight: $weight-semibold;
	}
}
