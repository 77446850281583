$level1padding: 1rem;
$level2padding: 0.5rem 2rem;
$level3padding: 0.5rem 3rem;
$level4padding: 0.5rem 4rem;
$level5padding: 0.5rem 5rem;

.tree-nodes {
	.tree-node {
		&.level-1 {
			border-bottom: 1px solid $border;
			margin-left: 0;

			&.is-open {
				padding-bottom: 0.5rem;
			}
		}

		&.is-active:not(.level-1) {
			& > .tree-node-toggle {
				color: $primary;
			}
		}

		.tree-node-toggle {
			display: block;
			width: 100%;
			text-align: left;

			&:hover,
			&:focus {
				background: rgba($color: $black, $alpha: 0.1);
			}

			.tree-node-title {
				font-weight: $weight-semibold;
			}
		}

		.toggle-icon {
			margin-right: 1rem;
		}

		// icons
		.node-icon {
			background: $grey;
			color: $white;
			width: 2rem;
			height: 2rem;
			border-radius: 10rem;
			display: inline-block;
			text-align: center;
			line-height: 2rem;
			margin-right: 1rem;
		}
		&:nth-of-type(1) .node-icon {
			background: $rich-turquoise;
		}
		&:nth-of-type(2) .node-icon {
			background: $vibrant-red;
		}
		&:nth-of-type(3) .node-icon {
			background: $rich-purple;
		}
		&:nth-of-type(4) .node-icon {
			background: $orange;
		}
		&:nth-of-type(5) .node-icon {
			background: $dark-green;
		}

		// indentation
		&.level-1 {
			& > .tree-node-toggle {
				padding: $level1padding;
			}
		}
		&.level-2 {
			& > .tree-node-toggle {
				margin-left: 0.5rem;
				padding: $level2padding;
			}
		}
		&.level-3 {
			& > .tree-node-toggle {
				margin-left: 0.5rem;
				padding: $level3padding;
			}
		}
		&.level-4 {
			& > .tree-node-toggle {
				margin-left: 0.5rem;
				padding: $level4padding;
			}
		}
		&.level-5 {
			& > .tree-node-toggle {
				margin-left: 0.5rem;
				padding: $level5padding;
			}
		}
	}

	.tree-node-link {
		display: block;
		color: $text;
		text-decoration: none;
		border-left: 0.5rem solid rgba($color: $primary, $alpha: 0);
		transition: border 0.35s ease-in-out;

		&:hover,
		&:focus {
			background: rgba($color: $black, $alpha: 0.1);
		}

		&.is-active {
			border-color: rgba($color: $primary, $alpha: 1);
			color: $primary;
			background: rgba($color: $black, $alpha: 0.035);
		}

		// Indentation
		&.level-2 {
			padding: $level2padding;
		}
		&.level-3 {
			padding: $level3padding;
		}
		&.level-4 {
			padding: $level4padding;
		}
		&.level-5 {
			padding: $level5padding;
		}
	}
}

.tree-panel-menu {
	display: grid;
	grid-auto-flow: column;
	gap: 0.5rem;
}
