.panel-header {
	display: grid;
	gap: 0.5rem;
	grid-template-columns: 1fr max-content;
	grid-template-areas:
		"left right"
		"left extra"
		"filters filters";
	padding: 1rem;
	border-bottom: 1px solid $border;
	position: relative;
	z-index: 101;
	background: $white;

	.header-left {
		grid-area: left;
	}
	.header-right {
		grid-area: right;
		text-align: right;
	}
	.header-extras {
		grid-area: extra;
		text-align: right;
	}
	.panel-filters {
		grid-area: filters;
		border-top: 1px solid $border;
		margin: 1rem -1rem -1rem;
	}

	@include mobile() {
		grid-template-areas:
			"left right"
			"extra extra";
	}

	.breadcrumb {
		color: $grey;
	}
	.subtitle {
		margin: 0.25rem 0;
	}
	.details {
		font-size: $size-7;

		.date {
			margin-right: 0.5rem;
		}
	}

	.panel-nav {
		$buttonSize: 3rem;
		margin-bottom: 0.5rem;

		.button.panel-nav-button {
			width: $buttonSize;
			height: $buttonSize;
			padding: 0;
			font-size: 1.25rem;
			color: $grey;
			background-color: $white;
			transition: all 0.1s ease-in-out;

			&:hover,
			&:focus {
				color: $primary;
				border-color: $primary;
				background-color: $soft-sky;
				position: relative;
				z-index: 1;
			}
		}
	}
}

.berichte-panel {
	.table-container {
		// overflow-x: initial;
		// overflow-y: initial;
	}
	.body {
		display: grid;
		grid-template-rows: max-content auto;
		overflow-y: hidden;
		position: relative;

		.panel-body {
			overflow-x: auto;
			overflow-y: hidden;
			position: relative;
			min-height: 420px;
			position: sticky;
			top: 0;
			z-index: 101;

			.panel-fake-footer {
				padding: 1rem;
				border-top: 1px solid $border;
			}
		}
	}
}

// GP
.gp-pos-title {
	.gp-title {
		span {
			white-space: initial;
			font-weight: $weight-bold;
		}
	}
	.gp-desc {
		color: $grey;
		white-space: wrap;
		padding-top: 0.5rem;
	}
}

// fitness
.fitness-graphics-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	justify-content: space-around;

	&.jedenKontaktNutzen {
		display: block;
	}

	&.is-widget-grid {
		grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));

		.fitness-progress-ring {
			padding: 1rem !important;

			.progress-ring {
				transform: scale(0.75);
			}
		}
	}
}
.fitness-progress-ring {
	padding: 3rem;
	display: grid;
	align-items: center;
	justify-items: center;

	.subtitle {
		max-width: 300px;
		text-align: center;
	}
}

.fitness-google-rank {
	padding: 3rem;
	display: grid;
	align-items: center;
	justify-items: center;
	border-bottom: 1px solid $border;
	background: $green-bg;

	.label {
		font-size: $size-4;
		font-weight: $weight-bold;
	}
	.rank {
		font-size: 3rem;
		font-weight: $weight-bold;
	}
}

.fitness-widget-grid {
	display: grid;
	grid-auto-flow: column;
	gap: 0.5rem;
	padding: 0.75rem 0.75rem 0;

	.fitness-grid-item {
		padding: 1rem;
		border-radius: $radius;

		&.bg-blue {
			border: 1px solid $blue;
			background: $blue-bg;
		}
		&.bg-green {
			border: 1px solid $green;
			background: $green-bg;
		}
		&.bg-yellow {
			border: 1px solid $yellow;
			background: $yellow-bg;
		}
		&.bg-turquoise {
			border: 1px solid $turquoise;
			background: $turquoise-bg;
		}
	}
}

.fitness-detail-nav {
	padding: 0.5rem;
	border-bottom: 1px solid $border;
	display: grid;
	grid-template-columns: 1fr max-content;
}
