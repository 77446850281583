html {
	font-family: $family-sans-serif;
	font-size: $body-size;
	text-rendering: $render-mode;
	color: $text;
}

img {
	height: auto;
	max-width: 100%;
	display: inline-block;
}

.hidden {
	opacity: 0;
}
