.agentur-filter-container {
	display: grid;
	gap: 0.5rem;
	padding: 0.5rem 1rem;
	align-items: center;
	justify-content: end;
	grid-template-columns: max-content 1fr max-content;

	.current-filters {
		display: grid;
		gap: 0;
		grid-template-columns: repeat(auto-fit, minmax(120px, max-content));
		justify-content: end;

		.form-control {
			margin: 0;

			.input,
			.react-select__control {
				height: $inputHeight;
				border-radius: 0;
			}

			&:first-of-type {
				.input,
				.react-select__control {
					border-top-left-radius: $radius;
					border-bottom-left-radius: $radius;
				}
			}
			&:last-of-type {
				.input,
				.react-select__control {
					border-top-right-radius: $radius;
					border-bottom-right-radius: $radius;
				}
			}

			.input,
			.is-readonly {
				&:not(:placeholder-shown) {
					background: rgba($color: $info, $alpha: 0.25);
					border: 1px solid $info;
				}
			}
		}

		.current-filter {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.toggle-filters {
		position: relative;

		.button {
			height: $inputHeight;
			min-width: $inputHeight;
			padding: 0 0.75rem;
			align-content: center;
			justify-content: center;
			color: $text-grey !important;

			&.is-info {
				background: rgba($color: $info, $alpha: 0.25) !important;
			}
		}

		.filter-count {
			position: absolute;
			top: 0;
			right: 0;
			font-size: $size-7;
			background: $turquoise;
			border-radius: $radius;
			color: $white;
			padding: 0 5px;
			display: none;
		}
	}
}

.agentur-filter-form {
	.agentur-filter-count {
		padding: 0.5rem;
		text-align: right;
		color: $text-grey;
	}

	.filters {
		padding-top: 0.5rem;
	}

	&.is-inline {
		display: grid;
		gap: 0.5rem;
		grid-template-columns: max-content 1fr;
		grid-template-areas: "count filters";

		.agentur-filter-count {
			grid-area: count;
		}

		.filters {
			grid-area: filters;
			display: grid;
			gap: 0;
			grid-template-columns: initial;
			grid-auto-flow: column;

			.form-control {
				margin: 0;

				input,
				.react-select__control {
					height: $inputHeight;
					border-radius: 0;
					min-width: 180px;
				}

				&:first-of-type {
					input,
					.react-select__control {
						border-radius: $radius 0 0 $radius;
					}
				}
				&:last-of-type {
					input,
					.react-select__control {
						border-radius: 0 $radius $radius 0;
					}
				}
			}
		}
	}
}

.agentur-maps-filter {
	position: absolute;
	background: $white;
	border: 1px solid $border;
	top: 0.5rem;
	left: 0.5rem;
	bottom: 0.5rem;
	border-radius: $radius;
	padding: 1rem;
	box-shadow: $shadow;
	overflow-x: hidden;
	overflow-y: auto;

	.form-control {
		width: 250px;
	}

	.agentur-filter-form {
		.agentur-filter-count {
			display: none;
		}
	}

	.modal-footer {
		display: none;
	}
}

.agt-list-load-filter {
	// border-top: 1px solid $border;
	// margin: 0 -1rem;
	// padding: 0.5rem 1rem;
}

/*
.filters {
		display: grid;
		gap: 0;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

		.form-control {
			margin: 0;

			input,
			.react-select__control {
				height: $inputHeight;
				border-radius: 0;
			}

			&:first-of-type {
				input,
				.react-select__control {
					border-radius: $radius 0 0 $radius;
				}
			}
			&:last-of-type {
				input,
				.react-select__control {
					border-radius: 0 $radius $radius 0;
				}
			}
		}
	}

	&.is-collapsed {
		&:not(.is-open) {
			.filters {
				.form-control:not(:first-child) {
					display: none;
				}
			}
		}

		&.is-open {
			.filters-body {
				position: absolute;
				right: 1rem;
				background: yellow;
			}
			.agentur-filter-count {
				align-self: flex-end;
				padding-bottom: 1.25rem;
			}
		}
	}

	.toggle-filters {
		position: relative;

		.button {
			height: calc(#{$navbar-height} - 0.5rem);
			width: calc(#{$navbar-height} - 0.5rem);
			padding: 0;
			align-content: center;
			justify-content: center;
			color: $text-grey !important;

			&.is-info {
				background: rgba($color: $info, $alpha: 0.25) !important;
			}
		}

		.filter-count {
			position: absolute;
			top: 0;
			right: 0;
			font-size: $size-7;
			background: $turquoise;
			border-radius: $radius;
			color: $white;
			padding: 0 5px;
		}
	}

	.form-control {
		// margin-top: 0 !important;

		input {
			height: calc(#{$navbar-height} - 0.5rem);

			&:not(:placeholder-shown) {
				background: rgba($color: $info, $alpha: 0.25);
				border: 1px solid $info;
			}
		}
	}

	.filter-actions {
		padding: 0.75rem 0;
	}
}

.agentur-filter-count {
	align-self: center;
	text-align: right;
	font-size: $size-7;
	color: $grey;
}
*/
