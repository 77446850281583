.agt-map-container {
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;

	.map-legend {
		position: absolute;
		bottom: 24px;
		right: 60px;
		background: $white;
		padding: 0.5rem;
		border-radius: $radius;
		display: grid;
		grid-auto-flow: column;
		gap: 1.25rem;
		box-shadow: $shadow;

		.map-legend-item {
			display: grid;
			grid-auto-flow: column;
			align-items: center;
			gap: 0.25rem;

			span {
				font-size: $size-7;
			}

			svg {
				max-height: 1.25rem;
				width: auto;
			}
		}
	}
}

.map {
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

.maps-agt-group {
	padding: 0.5rem;
}
.maps-agt-group-item {
	padding-top: 0.5rem;
}
