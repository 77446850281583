$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7;

html {
	font-family: $family-sans-serif;
	font-size: $body-size;
	color: $text;
}

.title,
.subtitle {
	// sizes
	@for $i from 1 through length($sizes) {
		$s: nth($sizes, $i);

		&.is-#{$i} {
			font-size: $s;
		}
	}
}

.title {
	font-family: $family-sans-serif;
	font-weight: $weight-bold;

	&.is-main {
		font-size: $size-4;
	}
}

.subtitle {
	font-family: $family-sans-serif;
	font-weight: $weight-normal;

	&.is-spaced {
		padding: 1rem 0;
	}
}

strong {
	font-weight: $weight-semibold;
}

.text-right {
	text-align: right;
}

.text-link {
	color: $action;
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.content {
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-weight: $weight-semibold;
		margin-bottom: 1rem;
	}

	h1 {
		font-size: $size-1;
	}

	h2 {
		font-size: $size-2;
	}

	h3 {
		font-size: $size-3;
	}

	h4 {
		font-size: $size-4;
	}

	h5 {
		font-size: $size-5;
	}

	p,
	ul {
		line-height: 150%;
		font-size: $size-6;
		margin-bottom: 1rem;

		li {
			list-style: disc;
			margin-left: 1rem;
		}

		a {
			color: $action;
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}
}

.quoted-text {
	background: $light-bg;
	padding: 1rem;
	border-radius: $radius;
	margin-top: 1rem;
	position: relative;

	&:before {
		content: open-quote;
		position: absolute;
		font-size: 3rem;
		top: -0.5rem;
		left: 0;
		font-family: sans-serif;
		color: $grey;
	}

	&:after {
		content: close-quote;
		position: absolute;
		font-size: 3rem;
		bottom: -2rem;
		right: 0;
		font-family: sans-serif;
		color: $grey;
	}
}

// general
@for $i from 1 through length($sizes) {
	$s: nth($sizes, $i);

	.is-size-#{$i} {
		font-size: $s;
	}
}
