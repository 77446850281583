.achievements-grid {
	display: grid;
	gap: 1rem;
	grid-template-columns: repeat(3, 1fr);
	justify-items: center;

	&.is-compact {
		grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
		gap: 1rem 0.5rem;

		.is-easter {
			display: inline-block;
		}
		.button {
			margin: 0;
			align-items: flex-start;
		}

		.achievements-icon {
			// .icons {
			// 	min-width: 4rem;
			// 	max-height: 4rem;
			// }
			.title {
				font-size: $size-7;
			}
		}
	}
}

.achievements-icon {
	text-align: center;

	.icons {
		position: relative;
		display: inline-grid;
		grid-template-areas: "icon";
		max-width: 4rem;

		.icon {
			grid-area: icon;
			display: inline-block;

			svg {
				max-width: 100%;
				height: auto;
			}
		}
	}

	.title {
		white-space: normal;
	}

	&.is-gsb {
	}

	&:hover,
	&:focus {
		opacity: 0.5;
	}

	&.is-fail {
		filter: grayscale(1);
		.icons {
			opacity: 0.4;
		}
		.title {
			opacity: 0.8;
		}

		svg {
			path[opacity="0.3"] {
				fill: $grey-light !important;
			}
		}
	}
}

.achievements-modal {
	.achievements-section {
		.section-title {
			background: $light-bg;
			padding: 0.5rem;
			text-align: center;
			font-size: $size-4;
			margin-bottom: 1rem;
		}
		.achievements-grid {
			padding: 1rem 1rem 2rem;
		}
	}

	.achievements-icon {
		.icons {
			max-width: 100%;
		}
	}
}

.easteregg-modal {
	&.grid {
		grid-template-columns: max-content 1fr;
		gap: 3rem;
	}
	.achievements-icon {
		pointer-events: none;
		.icons {
			display: block;
			padding: 1rem;
			max-width: 200px;

			.icon {
				width: 120px;
			}
		}
	}
	.details {
		padding: 1rem 0;

		.title {
			margin-bottom: 0.5rem;
		}
	}
}

// lead now
.achievement-icon {
	padding: 1rem;
	text-align: center;
	justify-self: center;

	.text {
		display: block;
		margin-top: 0.25rem;
	}

	&:hover,
	&:focus {
		opacity: 0.5;
	}
}

.errungenshaft-state-icon {
	display: inline-grid;
	background: $grey-lighter;
	position: relative;
	width: 35px;
	height: 35px;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	border-radius: 100%;

	.fa-icon {
		margin-top: 1px;
	}

	&.is-gold {
		background: $yellow;
	}
	&.is-bronze {
		background: $orange;
	}

	&.is-boolean {
		color: $white;
		background: $primary;
	}

	.tag {
		position: absolute;
		bottom: -8px;
		width: 35px;
		padding: 0;
		box-shadow: 0 0 0 1px $white;
	}
}
