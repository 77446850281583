@use "sass:math";

@keyframes tooltip-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

[data-tooltip] {
	// default = right
	position: relative;
	$arrowOffset: 16px;

	// content box
	&:before {
		display: none;
		content: attr(data-tooltip);
		position: absolute;
		left: calc(100% + 1rem);
		top: 50%;
		transform: translateY(-50%);

		background: $white;
		padding: 0.75rem;
		border-radius: $radius;
		border: 1px solid $primary;
		color: $primary;
		box-shadow: 0 0 1rem rgba($color: $black, $alpha: 0.25);

		text-transform: none;
		font-size: $size-6;
		font-weight: $weight-bold;
		max-width: 16rem;
		width: max-content;
		white-space: normal;

		animation: tooltip-in 0.15s ease-out;
	}

	// arrow
	&:after {
		display: none;
		content: "";
		position: absolute;
		width: 14px;
		height: 16px;
		right: -$arrowOffset;
		top: 50%;
		transform: translateY(-50%);
		background: url("/assets/images/tooltip-arrow.svg");
		animation: tooltip-in 0.15s ease-out;
	}

	&[data-tooltip-position="top"] {
		&:before {
			left: 50%;
			transform: translateX(-50%) translateY(0);
			top: auto;
			bottom: calc(100% + $arrowOffset - 4px);
		}
		&:after {
			left: 50%;
			right: auto;
			top: -$arrowOffset;
			transform: translateX(-50%) translateY(0) rotate(270deg);
		}
	}

	&[data-tooltip-position="bottom"] {
		&:before {
			left: 50%;
			transform: translateX(-50%) translateY(0);
			top: calc(100% + $arrowOffset - 4px);
		}
		&:after {
			left: 50%;
			right: auto;
			top: 100%;
			transform: translateX(-50%) translateY(0) rotate(90deg);
		}
	}

	&[data-tooltip-position="left"] {
		&:before {
			left: auto;
			right: calc(100% + 1rem);
			top: 50%;
			transform: translateY(-50%);
			background: white;
		}

		&:after {
			left: -$arrowOffset;
			right: auto;
			top: 50%;
			transform: translateY(-50%) rotate(180deg);
		}
	}

	&[data-tooltip-size="small"] {
		&:before {
			padding: 0.25rem 0.5rem;
		}
	}

	&:hover,
	&:focus {
		&:before,
		&:after {
			display: block;
			z-index: 200;

			@include mobile() {
				display: none;
			}
		}
	}
}
