.document-widget-item {
	display: grid;
	grid-template-columns: max-content 1fr;
	padding: 1rem;
	gap: 1rem;
	border-bottom: 1px solid $border;

	&:hover,
	&:focus {
		background: $light-bg;
	}

	.read-state {
		width: 1rem;
		height: 1rem;
		background: $primary;
		border-radius: 100%;
		border: 2px solid $primary;
	}

	&.is-read {
		.read-state {
			background: transparent;
		}
	}

	.button {
		display: block;
		font-weight: $weight-normal;
		border-radius: 0;
		text-align: left;
	}

	.html-content {
		display: block;
		max-height: 3.5rem;
		overflow: hidden;
		margin-bottom: 0.5rem;
	}

	.modified {
		font-size: $size-7;
		color: $text-light;
		padding-top: 0.5rem;
	}

	.sharing {
		display: grid;
		gap: 0.5rem;
		grid-auto-flow: column;
		justify-content: start;
	}
}
