.news-list {
	.news-list-item {
		padding: 1rem;

		.header {
			display: grid;
			grid-template-columns: 1fr max-content;
			padding-bottom: 1rem;
		}

		&:not(:last-child) {
			border-bottom: 1px solid $light-bg;
		}
	}
}
