.form-control {
	position: relative;

	& + .form-control {
		margin-top: 0.5rem;
	}

	&.is-inline {
		display: grid;
		grid-template-areas: "label input";
		grid-template-columns: 0.4fr 1fr;
		gap: 1rem;
		// align-items: center;

		@include mobile() {
			gap: 0.25rem;
			grid-template-columns: 1fr;
			grid-template-rows: max-content max-content;
			margin-bottom: 1rem;
		}
	}

	&.has-icon {
		.fa-icon {
			grid-area: input;
			position: absolute;
			left: 0.5rem;
			top: 50%;
			transform: translateY(-50%);
			color: $grey-light;
		}

		input[type="text"] {
			text-indent: 1.25rem;
		}
	}

	label {
		grid-area: label;
		text-align: start;
		font-weight: $weight-bold;
		font-size: $size-6;
		display: block;
		color: $grey;

		display: grid;
		gap: 0.5rem;
		grid-template-columns: max-content 1fr;
		padding-top: 9px;
	}

	&:not(.is-inline) label {
		margin-bottom: 0.5rem;
	}

	input[type="text"],
	input[type="date"],
	input[type="password"],
	input[type="email"],
	input[type="number"] {
		@extend .inputbase;
	}

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&.has-errors {
		input[type="text"],
		.drop-area {
			border-color: $error;
		}
		label {
			color: $error;
		}
	}

	&.is-checkbox {
		grid-template-areas: "label input";

		input {
			grid-area: input;
		}

		&.is-aligned-left {
			grid-template-areas: "input label";
			grid-template-columns: max-content 1fr;
		}
	}

	&.has-unit-display {
		display: grid;
		grid-template-columns: 1fr max-content;

		input[type="text"],
		input[type="date"],
		input[type="password"],
		input[type="email"],
		input[type="number"] {
			border-radius: $radius 0 0 $radius;
		}

		.unit-display {
			padding: 0.5rem;
			display: block;
			height: $inputHeight;
			border: 1px solid $border;
			border-left: 0;
			border-radius: 0 $radius $radius 0 !important;
			background: $almost-white;
		}
	}

	.clear-button {
		background: transparent;
		border: 0;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		color: $text-light;
		padding: 0.5rem;
		cursor: pointer;
		outline: none;

		&:hover,
		&:focus {
			color: $primary;
		}
	}

	&.date-with-clear-button {
		.grid {
			grid-template-columns: max-content max-content;
			gap: 0;
		}

		input {
			display: inline-block;
			width: max-content;
		}

		.clear-button {
			position: relative;
		}
	}

	&.is-divider {
		padding: 0.5rem 0 0.25rem;

		label {
			color: $text;
		}

		.children {
			color: $black;
			padding-top: 9px;
		}

		.divider {
			background: $border;
			height: 2px;
			border-radius: 100px;
			align-self: center;
		}
	}

	.input-tip {
		color: $rich-red;
		font-size: $size-7;
	}
}

.help {
	font-size: $size-7;

	&.is-danger {
		color: $error;
	}
}

.form {
	.label {
		text-align: start;
		font-weight: $weight-bold;
		font-size: $size-6;
		display: block;
		margin-bottom: 0.5rem;
		color: $grey;
	}

	.title {
		margin-bottom: 1rem;
		display: block;
	}

	.control {
		display: inline-block;
	}

	hr {
		border: 1px solid $border;
		margin: 1rem 0;
	}

	.field {
		// margin-bottom: 1rem;

		p {
			margin-bottom: 1rem;
		}

		&.has-line {
			border-bottom: 1px solid $border;
			padding-bottom: 1rem;
			margin-bottom: 2rem;
		}

		&.is-inline {
			display: grid;
			gap: 1rem;
			grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

			@include mobile() {
				grid-auto-flow: row;
				gap: 0.5rem;
			}

			.form-control {
				margin-bottom: 1rem;
			}

			.control {
				.button,
				.input,
				.text-area {
					border-radius: $radius;
					width: 100%;
				}
			}

			.control:first-of-type {
				.button,
				.input,
				.text-area {
					border-radius: $radius 0 0 $radius;

					@include mobile() {
						border-radius: $radius;
					}
				}
			}
			.control:last-of-type {
				.button,
				.input,
				.text-area {
					border-radius: 0 $radius $radius 0;

					@include mobile() {
						border-radius: $radius;
					}
				}
			}
		}
	}
}

.is-support-form {
	.text-area {
		min-height: 200px;
	}
}
