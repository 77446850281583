.agt-betreuer-widget {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.5rem;

	@include mobile() {
		grid-template-columns: 1fr;
	}

	.betreuer-widget-item {
		display: grid;
		grid-template-columns: max-content 1fr;
		align-items: center;
		gap: 0.5rem;
	}

	.tag {
		margin: 0;
		width: 4rem;
	}
}

.agt-list-container {
	overflow: hidden;
	height: 100%;

	.agentur-filter-container {
		height: 50px;
	}

	.table-container {
		height: calc(100% - 50px); // needed for right-scroll on windows
	}
}

.agt-list-schwerpunkt {
	display: inline-grid;
	grid-template-columns: repeat(3, 1fr);
}

.agt-selector-list {
	max-height: 100%;
	display: grid;
	grid-template-rows: max-content 1fr;
	gap: 0.5rem;
	overflow: hidden;
	padding-top: 0.5rem;

	.filter {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.list {
		overflow-y: auto;
	}
}
