.text-link {
	text-decoration: none;
	color: $action;

	&:hover,
	&:focus {
		color: darken($color: $action, $amount: 10);
	}
}

.image {
	display: inline-block;
}

.svg-icon {
	display: inline-block;

	svg {
		display: inline-block;
	}
}
