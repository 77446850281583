.fr-box {
	.fr-toolbar {
		border-color: $border !important;

		&.fr-top {
			border-color: $border !important;
			border-top-left-radius: $radius;
			border-top-right-radius: $radius;
		}
	}
	.fr-wrapper {
		border-color: $border !important;
	}
	.fr-btn {
		color: $text !important;
		height: 36px !important;

		svg {
			width: 20px !important;
			height: 20px !important;

			path {
				fill: $text !important;
			}
		}
	}
	.fr-second-toolbar {
		border-color: $border !important;
		border-bottom-left-radius: $radius;
		border-bottom-right-radius: $radius;
	}
}
