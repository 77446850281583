.bp-unit-switch {
	$height: 24px;
	$width: 13rem;
	$bewertungColor: $primary;
	$branchenColor: $vibrant-purple;
	$trans: 0.15s ease-in-out;

	display: block;
	background: $bewertungColor;
	height: $height;
	width: $width;

	overflow: hidden;
	border-radius: 10rem;

	box-shadow: inset 0 0 0.5rem rgba($color: $black, $alpha: 0.5), inset 0 0 0 1px rgba($color: $black, $alpha: 0.25);
	position: relative;

	transition: all $trans;

	&:after {
		content: "";
		position: absolute;
		right: 2px;
		top: 2px;
		width: $height - 4px;
		height: $height - 4px;
		border-radius: 10rem;
		display: block;
		background: $white;
		box-shadow: 0 0 0.5rem rgba($color: $black, $alpha: 0.5);
		transition: right $trans;
	}

	.inner {
		display: grid;
		grid-auto-flow: column;
		position: absolute;
		transition: left $trans;
		left: 0;

		.bp-value {
			display: grid;
			align-content: center;
			color: $white;
			height: $height;
			width: $width;
			font-weight: $weight-normal;
			text-transform: uppercase;
			color: white;

			&.value-brancheneinheit {
				padding-left: 1rem;
			}
		}
	}

	&:hover,
	&:focus {
		box-shadow: inset 0 0 100px rgba($color: $black, $alpha: 0.5);
	}

	&.is-brancheneinheit {
		background: $branchenColor;

		&:after {
			content: "";
			right: calc(100% - #{$height - 2});
		}
		.inner {
			left: -100%;
		}
	}
}
