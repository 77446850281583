@use "sass:math";

.button {
	$paddingV: 0.5rem;
	$paddingH: 1rem;

	display: inline-grid;
	grid-auto-flow: column;
	gap: 0.5rem;
	align-items: center;
	justify-content: center;
	position: relative;

	// outline: none;
	border: 0;
	border-radius: $radius;

	font-family: $family-sans-serif;
	font-weight: $weight-normal;
	color: $text;

	text-align: center;
	white-space: nowrap;
	line-height: normal;
	font-size: $size-6;
	cursor: pointer;

	transition: 0.1s ease-in-out;

	&:not(.is-text) {
		padding: $paddingV $paddingH;
		background: $soft-grey;
		@include border-shadow();
		text-decoration: none;
		text-transform: uppercase;
	}

	// // has icon
	// i[class^="fa"] {
	// 	vertical-align: middle;
	// }

	// span + i[class^="fa"],
	// i[class^="fa"] + span {
	// 	margin-left: 0.5rem;
	// }

	&:hover,
	&:focus {
		background: darken($color: $soft-grey, $amount: 10);
	}

	&.is-inverted {
		background: $white;

		&:hover,
		&:focus {
			color: $action;
		}
	}

	&.is-small {
		font-size: $size-7;
		padding: $paddingV math.div($paddingH, 2);
	}

	&.is-medium {
		padding: $paddingV * 2 $paddingH;
		font-size: $size-4;
	}

	&.is-large {
		padding: $paddingV * 3 $paddingH;
		font-size: $size-4;
	}

	&.is-compact {
		padding-left: math.div($paddingH, 2);
		padding-right: math.div($paddingH, 2);
		font-size: $size-6;

		&.has-icon {
			i[class^="fa"] {
				margin-right: 0.25rem;
			}
		}
	}

	&.is-round {
		border-radius: 100rem;
	}

	&.is-block {
		width: 100%;
	}

	$buttonColors: (
		"primary": $primary,
		"blue": $blue,
		"green": $green,
		"yellow": $yellow,
		"warning": $yellow,
		"red": $red,
		"danger": $red,
		"light": $text-light,
		"info": $info,
		"purple": $purple,
		"turquoise": $rich-turquoise,
	);

	@each $name, $color in $buttonColors {
		&.is-#{$name} {
			&:not(.is-inverted) {
				background-color: $color;
				color: getButtonColor($color);

				&:hover,
				&:focus {
					background: darken($color: $color, $amount: 5);
				}
			}

			&.is-inverted {
				background: $white;
				@include border-shadow($color, 1, 1px);
				color: getButtonColor($color, $color);

				&:hover,
				&:focus {
					background: $color;
					color: getButtonColor($color);
				}
			}
		}
	}

	// override for inverted light
	&.is-light {
		&.is-inverted {
			@include border-shadow($grey-light, 1, 1px);
		}
	}

	&:disabled:not(.is-inverted),
	&:disabled:not(.is-inverted):hover {
		background: $grey-light;
		cursor: not-allowed;
	}

	&.is-inverted:disabled,
	&.is-inverted:disabled:hover {
		color: $grey-light;
		cursor: not-allowed;
	}

	&.is-text {
		background-color: transparent;
		border: none;
		box-shadow: none;
		cursor: pointer;
		text-decoration: underline;
		display: inline;
		margin: 0;
		padding: 0;
		color: $primary;
		font-weight: $weight-normal;
		text-transform: initial;

		&:hover,
		&:focus {
			color: darken($primary, 10);
		}

		&.is-padded {
			padding: 0.5rem;
		}

		&.is-grey {
			color: $grey;

			&:hover,
			&:focus {
				color: darken($grey, 10);
			}
		}
	}
}

.button.is-icon {
	background: transparent;
	border: 0;
	padding: 0.5rem 0.75rem;
	display: inline-block;
	color: $text;
	font-size: 1.2rem;
	cursor: pointer;
	box-shadow: none;

	i[class^="fa"] {
		vertical-align: middle;
	}

	&.is-small {
		font-size: 1rem;
		padding: 0.5rem;
	}

	&:hover:not(.is-readonly) {
		color: $primary;
	}

	&:focus:not(.is-readonly) {
		color: $primary;
	}

	&.has-notification {
		position: relative;
		$color: $primary;
		$size: 8px;

		border: 1px solid $color;
		background: $grey;

		&:after {
			content: " ";
			display: block;
			background: $color;
			width: $size;
			height: $size;
			border-radius: 50%;
			position: absolute;
			top: math.div($size, -2);
			right: math.div($size, -2);
		}
	}

	&:disabled,
	&.is-readonly {
		cursor: default;
	}

	&.is-grey {
		color: $grey-light;
	}
	&.is-primary {
		color: $primary;
	}
}

.button + .button {
	margin-left: 0.5rem;
}
.button.is-block + .button.is-block {
	margin-left: 0;
	margin-top: 0.5rem;
}

.button.is-blank {
	background: transparent;
	border: 0;
	cursor: pointer;
	padding: 0;
	box-shadow: none;

	color: $black;
	text-transform: none;
	text-decoration: none;
	text-align: center;
	white-space: nowrap;
	line-height: normal;
	font-family: $family-sans-serif;
	font-size: $size-6;
	font-weight: $weight-bold;

	&:hover,
	&:focus {
		background: transparent;
		color: $primary;
	}
}
