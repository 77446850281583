.schwerpunkt-edit-list {
	padding-bottom: 1rem;
	display: grid;
	gap: 0.5rem;
	grid-auto-flow: row;

	.schwerpunkt-edit-item {
		display: grid;
		gap: 0.5rem;
		grid-template-columns: max-content 1fr;
		align-content: center;
	}
}
