.table.is-notiz-table {
	.html-content {
		display: block;
		max-height: 3.75rem;
		overflow: hidden;
		margin-bottom: 0.5rem;

		& > *:first-child {
			font-weight: $weight-bold;
		}
	}
}

.notiz-widget-item {
	.button {
		display: block;
		font-weight: $weight-normal;
		border-bottom: 1px solid $border;
		padding: 1rem;
		border-radius: 0;
		width: 100%;
		text-align: left;

		&:hover,
		&:focus {
			background: $light-bg;
		}
	}

	.html-content {
		display: block;
		max-height: 3.5rem;
		overflow: hidden;
		margin-bottom: 0.5rem;

		& > *:first-child {
			font-weight: $weight-bold;
		}
	}

	.modified {
		font-size: $size-7;
		color: $text-light;
		padding-top: 0.5rem;
	}
}
