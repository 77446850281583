@keyframes flyout-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.flyout {
	$arrowSize: 8px;

	position: absolute;
	// right: calc(100% + 1rem);
	// top: 50%;
	// transform: translateY(-50%);

	background: $white;
	padding: 0.75rem;
	border-radius: $radius-small;
	color: $primary;
	box-shadow: 0 0 1rem rgba($color: $black, $alpha: 0.25);

	font-size: $size-7;
	font-weight: $weight-bold;
	max-width: 15rem;
	width: max-content;

	animation: flyout-in 0.15s ease-out;
	z-index: 10;
	margin-top: $arrowSize;

	&:after {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		border-top: $arrowSize solid transparent;
		border-bottom: $arrowSize solid $white;
		border-left: $arrowSize solid transparent;
		border-right: $arrowSize solid transparent;
		top: -$arrowSize * 2;
		left: 50%;
		transform: translateX(-50%);
		animation: flyout-in 0.15s ease-out;
	}

	.title {
		margin-bottom: 0.25rem;
	}
}
