.main-menu {
	background: $white;
	position: relative;
	box-shadow: $shadow;
	display: grid;
	grid-template-rows: max-content 1fr max-content;
	z-index: 100;

	.main-menu-header {
		padding: 1rem 2rem;
		position: relative;

		.user-details {
			.name {
				display: block;
				padding-bottom: 0.25rem;
			}
			span {
				display: block;
				color: $text-light;
			}
		}

		.main-menu-close {
			position: absolute;
			top: 0.75rem;
			right: 1.5rem;

			@include desktop() {
				display: none;
			}

			button {
				font-size: 2rem;
			}
		}

		.main-menu-collapse {
			position: absolute;
			top: 1.75rem;
			right: 0.25rem;

			.button {
				color: grey;
				font-size: $size-2;
			}

			@include tablet() {
				display: none;
			}
		}
	}

	nav {
		padding: 1rem 2rem;

		.nav-item {
			display: grid;
			padding: 0.25rem 0;
			gap: 1rem;
			grid-template-columns: 2rem 1fr;
			color: $text-grey;
			text-decoration: none;
			font-size: $size-6;
			margin-bottom: 0.5rem;
			align-items: center;
			font-weight: $weight-semibold;

			.icon {
				height: 2rem;
				width: 2rem;
				border-radius: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.text {
				padding-right: 1rem;
			}

			&.is-active {
				color: $text;
				.icon {
					background: $primary;
					color: $white;
				}
			}

			&:hover,
			&:focus,
			&:active {
				color: $action;
				.icon {
					background: $action;
					color: $white;
				}
			}
		}
	}

	.main-menu-footer {
		padding: 0.5rem;
		display: grid;
		grid-template-columns: max-content 1fr;
	}

	@include tablet() {
		position: fixed;
		width: 100vw;
		height: 100vh;
		transform: translateX(-100%);
		transition: transform 0.15s ease-in-out;

		&.is-active-mobile {
			transform: translateX(0);
		}
	}

	&:not(.is-collapsed) {
		.main-menu-footer,
		nav {
			[data-tooltip] {
				&:before,
				&:after {
					display: none;
				}
			}
		}
	}
	&.is-collapsed {
		@include desktop() {
			.main-menu-collapse {
				left: 50%;
				right: auto;
				transform: translateX(-50%);
				top: 1rem;

				button {
					font-size: 2rem !important;
				}
			}
			.user-details {
				display: none;
			}
			nav {
				padding-left: 1rem;
				padding-right: 1rem;
				margin-top: 3rem;

				.nav-item {
					gap: 0;
					.text {
						display: none;
					}
				}
			}
			.main-menu-footer {
				grid-template-columns: 1fr;
				grid-auto-flow: row;
				text-transform: none;

				.button {
					margin: 0 0 0.5rem;

					span {
						display: none;
					}
				}
			}
		}
	}
}
