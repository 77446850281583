.tag {
	$paddingV: 0.25rem;
	$paddingH: 0.75rem;
	padding: $paddingV $paddingH;
	border-radius: 5rem;
	background: $grey-lighter;
	color: $black;
	font-size: $size-6;
	display: inline-block;
	text-align: center;
	border: 1px solid $grey-lighter;
	white-space: nowrap;

	& + .tag {
		margin-left: 0.25rem;
	}

	&.is-inline {
		margin: 0 0.25rem;
	}

	&.is-small {
		padding: 0.1rem 0.5rem;
		font-size: $size-7;
	}

	&.is-inverted {
		background: $white;
		border: 1px solid $grey;
	}

	$tagColors: (
		"primary": $primary,
		"blue": $blue,
		"green": $green,
		"yellow": $yellow,
		"red": $red,
		"info": $info,
		"purple": $purple,
		"turquoise": $turquoise,
	);

	@each $name, $color in $tagColors {
		&.is-#{$name} {
			&:not(.is-inverted) {
				background-color: $color;
				border-color: $color;
				color: getButtonColor($color);
			}

			&.is-inverted {
				background: $white;
				border-color: $color;
				color: getButtonColor($color, $color);
			}
		}
	}

	// &.is-blue {
	// 	color: $white;
	// 	background: $primary;
	// }
	// &.is-green {
	// 	color: $white;
	// 	background: $dark-green;
	// }
	// &.is-red {
	// 	color: $white;
	// 	background: $vibrant-red;
	// }
	// &.is-yellow {
	// 	color: $text;
	// 	background: $vibrant-yellow;
	// }

	// &.is-purple {
	// 	color: $white;
	// 	background: $purple;
	// }
}

.tag-list {
	.tag {
		margin-left: 0;
		margin-right: 0.25rem;
		margin-bottom: 0.25rem;
	}
}
