.agt-selector {
	display: grid;
	grid-template-columns: 1fr max-content;

	.is-agt-selector {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
	}

	.is-agt-select-reset {
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		margin-left: -1px !important;
	}
	&.is-active {
		.is-agt-selector {
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;

			.fa {
				display: none;
			}
		}
	}
}
