@import "~noty/src/noty.scss";
@import "~noty/src/themes/mint.scss";

.noty_layout {
	.noty_theme__mint.noty_bar {
		box-shadow: 0 0 1rem rgba($color: $black, $alpha: 0.2);
		border-bottom: 0;

		.noty_body {
			position: relative;
			border: 0;
			border-left: 5px solid $grey;
			padding: 1rem 1rem 1rem 3rem;
			color: $text;
			background: $white;

			.fa {
				color: $grey;
				position: absolute;
				left: 0.75rem;
				font-size: 1.2rem;
				top: 0;
				bottom: 0;
				display: flex;
				align-items: center;
			}
		}

		&.noty_type__success {
			.noty_body {
				border-color: $green;
				.fa {
					color: $green;
				}
			}
		}

		&.noty_type__info,
		&.noty_type__information {
			.noty_body {
				border-color: $info;
				.fa {
					color: $info;
				}
			}
		}

		&.noty_type__alert {
			.noty_body {
				border-color: $warning;
				.fa {
					color: $warning;
				}
			}
		}

		&.noty_type__error {
			.noty_body {
				border-color: $error;
				.fa {
					color: $error;
				}
			}
		}

		&.noty_type__warning {
			.noty_body {
				border-color: $orange;
				.fa {
					color: $orange;
				}
			}
		}
	}
}
