.form-control {
	.select {
		position: relative;
		&:after {
			content: " ";
			position: absolute;
			right: 1rem;
			top: 50%;
			width: 10px;
			height: 10px;
			margin-top: -6.5px;
			border: 3px solid $primary;
			border-radius: 2px;
			border-top: 0;
			border-left: 0;
			transform: rotate(45deg);
			transform-origin: center;
			pointer-events: none;
		}

		&.is-disabled {
			&:after {
				border-color: $grey-light;
			}
		}

		select {
			@extend .inputbase;
			appearance: none;
			padding-right: 2rem;
			position: relative;

			option {
				padding: 0.5rem 0;
			}
		}

		&.has-no-value {
			select {
				color: $placeholder;
			}
		}
	}
}
