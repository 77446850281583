.debug {
	display: none;
}

.application {
	&.allow-debug {
		.debug {
			display: block;
		}
	}
	.app-version {
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 0.25rem;
		font-size: 10px;
		background: rgba($color: $white, $alpha: 0.5);
	}
}

.app-container {
	height: 100vh;
	display: grid;
	grid-template-areas:
		"main-menu navbar action-controls"
		"main-menu app-content app-content";
	grid-template-columns: max-content 1fr max-content;
	grid-template-rows: max-content 1fr;
	overflow: hidden;

	.navbar {
		grid-area: navbar;
	}
	.action-controls {
		grid-area: action-controls;
	}
	.main-menu {
		grid-area: main-menu;
	}
	.app-content {
		grid-area: app-content;
		// overflow: hidden;
		overflow-x: hidden;
		overflow-y: auto;
	}

	@include mobile() {
		grid-template-areas:
			"navbar action-controls"
			"app-content app-content";

		grid-template-columns: 1fr max-content;
	}
}

.generic-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba($color: $black, $alpha: 0.3);
	z-index: 100;
}

.main-title {
	display: grid;
	grid-template-columns: max-content 1fr;
	align-items: center;

	.actions {
		justify-self: end;
	}
}

.busy-screen {
	display: flex;
	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.8);
}

.loader {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;

	&:before,
	&:after {
		content: "";
		position: absolute;
		border: 4px solid $action;
		opacity: 1;
		border-radius: 50%;
		animation: loadingAnimation 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}

	&:after {
		animation-delay: -0.5s;
	}
}

@keyframes loadingAnimation {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}
