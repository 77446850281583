.admin-navbar {
	display: grid;
	grid-template-columns: max-content 1fr;
	align-items: center;
}

.admin-actions {
	padding: 1rem;
	border-bottom: 1px solid $border;
	display: flex;
	flex-flow: row wrap;
	gap: 0.5rem;

	button {
		margin: 0 !important;
	}

	.divider {
		border-left: 2px solid $border;
	}
}

.admin-date-select {
	padding: 1rem 1rem 2rem;
	display: grid;
	gap: 1rem;
	grid-template-columns: 1fr 1fr 1fr max-content;
	align-items: flex-end;
}

.admin-container {
	.main-menu {
		overflow-y: auto;
	}
}

.admin-usage-filter {
	.usage-date {
		grid-template-columns: max-content;
		justify-content: end;
	}
}
