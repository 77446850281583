.filetype-icon {
	$size: 2rem;
	height: $size;
	width: $size;
	position: relative;
	border-radius: 10rem;
	display: grid;
	align-content: center;

	.fa {
		font-size: 1.1rem;
		color: $white;
	}

	&.filetype-default {
		background: $grey;
	}
	&.filetype-image {
		background: $rich-turquoise;
	}
	&.filetype-audio {
		background: $vibrant-pink;
		color: $text;
	}
	&.filetype-video {
		background: $vibrant-orange;
		color: $text;
	}
	&.filetype-pdf {
		background: $vibrant-red;
	}
	&.filetype-word {
		background: $info;
	}
	&.filetype-excel {
		background: $rich-green;
	}
	&.filetype-ppt {
		background: $rich-red;
	}
	&.filetype-text {
		background: $vibrant-yellow;
		color: $text;
	}
	&.filetype-html {
		background: $grey;
	}
	&.filetype-json {
		background: $grey;
	}
	&.filetype-zip {
		background: $vibrant-green;
		color: $text;
	}
}
