.agentur-segment-tag {
	&.is-starter {
		background: $purple;
		color: $white;
	}
	&.is-potential {
		background: $blue;
		color: $white;
	}
	&.is-entwicklungsagentur {
		background: $red;
		color: $white;
	}
	&.is-breitenagentur {
		background: $yellow;
		color: $text;
	}
	&.is-unternehmer {
		background: $green;
		color: $white;
	}
}

.segment-table-tag {
	display: inline-grid;
	grid-template-columns: max-content 1fr;
	gap: 0.5rem;
	align-items: center;
	color: $text;

	.segment-icon {
		background: $grey-light;
		border-radius: 100vw;
		width: 2rem;
		height: 2rem;
		display: grid;
		align-items: center;
		justify-content: center;
		color: $white;
	}

	&.is-calculated,
	&.is-custom {
		&.is-starter {
			.segment-icon {
				background: $purple;
			}
		}
		&.is-potential {
			.segment-icon {
				background: $blue;
			}
		}
		&.is-entwicklungsagentur {
			.segment-icon {
				background: $red;
			}
		}
		&.is-breitenagentur {
			.segment-icon {
				background: $yellow;
				color: $text;
			}
		}
		&.is-unternehmer {
			.segment-icon {
				background: $green;
			}
		}
	}
}
