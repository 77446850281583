.modal-container {
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100vw;
	z-index: 200;
	display: grid;
	align-items: center;
	justify-content: center;

	.modal-background {
		background-color: rgba($black, 0.4);
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.modal {
		$modalPadding: 1rem;
		background: $white;
		border-radius: $radius;
		overflow: hidden;
		position: relative;
		max-height: 95vh;
		max-width: 95vw;
		display: grid;
		grid-template-rows: max-content 1fr max-content;

		&.is-small {
			max-width: 450px;
			min-width: 250px;
		}
		&.is-medium {
			min-width: 600px;
			max-width: 800px;
		}
		&.is-large {
			min-width: 800px;
			max-width: 95vw;
		}
		&.is-fullscreen {
			width: calc(100vw - #{$modalPadding});
			height: calc(100vh - #{$modalPadding});
			max-height: 98vh;
			max-width: 98vw;
		}
		&.is-full-height {
			height: calc(100vh - #{$modalPadding});
			max-height: 98vh;
		}
		&.is-side-panel {
			height: calc(100vh - #{$modalPadding});
			max-height: 98vh;
			min-width: 35vw;
			position: absolute;
			right: 1rem;
		}

		@include mobile() {
			width: calc(100vw - #{$modalPadding});
			height: calc(100vh - #{$modalPadding});
			min-width: auto !important;
			min-height: auto !important;
			max-width: 100vw !important;
			max-height: 100vh !important;
		}

		.modal-header {
			position: relative;
			border-bottom: 1px solid $border;
			padding: $modalPadding;

			.title {
				font-weight: $weight-semibold;
				font-size: $size-4;
				padding-right: 2rem;
			}

			.modal-close {
				position: absolute;
				right: 0.5rem;
				top: 0.5rem;

				&:before,
				&:after {
					content: " ";
					position: absolute;
					top: 50%;
					left: 50%;
					// background: $grey-dark;
					width: 65%;
					height: 2px;
					transform-origin: center;
				}

				&:hover,
				&:focus {
					&:before,
					&:after {
						height: 4px;
						border-radius: $radius-small;
					}
				}

				&:before {
					transform: translateX(-50%) translateY(-50%) rotate(45deg);
				}
				&:after {
					transform: translateX(-50%) translateY(-50%) rotate(-45deg);
				}
			}
		}

		.modal-body {
			padding: $modalPadding;
			overflow-x: hidden;
			overflow-y: auto;
		}

		&.no-footer {
			.modal-body {
				margin-bottom: 0;
			}
		}

		&.is-table-modal {
			.modal-body {
				padding-left: 0;
				padding-right: 0;

				.modal-footer {
					padding-right: $modalPadding;
					padding-left: $modalPadding;
					margin: 0;
				}
			}

			.table {
				th,
				td {
					&:first-of-type {
						padding-left: $modalPadding;
					}
					&:last-of-type {
						padding-right: $modalPadding;
					}
				}
			}
		}

		&.is-form-modal {
			.modal-body {
				padding-bottom: 4rem;
				.modal-footer {
					background: $white;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					padding: 1rem !important;
					margin: 0 !important;
					height: 60px;
					z-index: 100;
				}
			}
		}

		&.is-paddingless {
			.modal-body {
				padding: 0;
			}
		}

		.modal-footer {
			text-align: right;
			padding: $modalPadding;
			border-top: 1px solid $border;

			.button {
				margin: 0 0.25rem;
			}
		}

		&:not(.is-paddingless) {
			.modal-body .modal-footer {
				padding-bottom: 0;
				margin-top: $modalPadding;
				margin-left: -$modalPadding;
				margin-right: -$modalPadding;
			}
		}
	}
}
