$fontpath: "../fonts/allianz_neo/";

@font-face {
	font-family: "Allianz Neo";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url($fontpath + "AllianzNeoW01-Light.woff2") format("woff2"), url($fontpath + "AllianzNeoW01-Light.woff") format("woff");
}

@font-face {
	font-family: "Allianz Neo";
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url($fontpath + "AllianzNeoW01-LightItalic.woff2") format("woff2"), url($fontpath + "AllianzNeoW01-LightItalic.woff") format("woff");
}

@font-face {
	font-family: "Allianz Neo";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url($fontpath + "AllianzNeoW01-Regular.woff2") format("woff2"), url($fontpath + "AllianzNeoW01-Regular.woff") format("woff");
}

@font-face {
	font-family: "Allianz Neo";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url($fontpath + "AllianzNeoW01-Italic.woff2") format("woff2"), url($fontpath + "AllianzNeoW01-Italic.woff") format("woff");
}

@font-face {
	font-family: "Allianz Neo";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url($fontpath + "AllianzNeoW01-SemiBold.woff2") format("woff2"), url($fontpath + "AllianzNeoW01-SemiBold.woff") format("woff");
}

@font-face {
	font-family: "Allianz Neo";
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url($fontpath + "AllianzNeoW01-SemiBoldIt.woff2") format("woff2"), url($fontpath + "AllianzNeoW01-SemiBoldIt.woff") format("woff");
}

@font-face {
	font-family: "Allianz Neo";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url($fontpath + "AllianzNeoW01-Bold.woff2") format("woff2"), url($fontpath + "AllianzNeoW01-Bold.woff") format("woff");
}

@font-face {
	font-family: "Allianz Neo";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url($fontpath + "AllianzNeoW01-BoldItalic.woff2") format("woff2"), url($fontpath + "AllianzNeoW01-BoldItalic.woff") format("woff");
}
