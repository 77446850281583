.leadnow-legend {
	display: grid;
	grid-auto-flow: row;
	gap: 1rem;

	.legend-item {
		display: grid;
		grid-template-columns: max-content 1fr;
		gap: 1rem;
		align-items: center;
	}
}

.leadnow-intro {
	display: grid;
	grid-template-columns: minmax(500px, 600px) 1fr;

	@include mobile() {
		grid-template-columns: 5fr 1fr;
	}

	.icon {
		align-self: center;
		text-align: right;
		padding-left: 3rem;
		padding-right: 3rem;

		@include mobile() {
			padding: 1rem;
			align-self: start;
		}

		svg {
			width: 80px;
			height: auto;

			@include mobile() {
				width: 30px;
			}
		}
	}
}
