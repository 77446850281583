.planungstool {
	.is-collapser-button {
		border-bottom: 1px solid $border;
		box-shadow: none;
		border-radius: 0;
		width: 100%;
		text-align: left;
		background: $white;
		color: $primary;
		padding: 1rem;
		font-weight: $weight-semibold;
		text-transform: none;

		&:first-of-type {
			border-top: 1px solid $border;
		}

		&:hover,
		&:focus {
			background: $light-bg;
		}
	}
}

.ap-form {
	label {
		grid-area: auto;
	}

	.ap-section {
		border-top: 1px solid $border;
		padding-top: 1rem;

		.ap-section-title {
			padding-bottom: 1rem;
			font-size: $size-5;
			display: block;
		}
	}

	.form-control.is-inline {
		grid-template-columns: 0.8fr 1fr;
	}
}
