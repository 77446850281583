.checklist {
	max-width: 100%;
	max-height: 100%;
	overflow-y: auto;

	.checklist-intro {
		padding: 1rem;
	}

	.checklist-form {
		width: 100%;

		td {
			padding: 0.5rem 1rem;
		}

		tbody {
			&.is-overall {
				&:before,
				&:after {
					content: "";
					display: block;
					height: 2rem;
				}

				.checklist-group-title {
					background: $white;

					th {
						padding: 1rem;
					}
				}

				.checklist-group-item {
					.button-group {
						margin-top: 1rem;
					}
				}
			}
		}
	}

	.checklist-group-title {
		background: $grey-bg;
		border-top: 1px solid $border;
		border-bottom: 1px solid $border;

		th {
			padding: 2.5rem 1rem;
			font-size: $size-4;
			font-weight: $weight-semibold;
		}
	}

	.checklist-group-item {
		border-top: 1px solid $border;
		td {
			vertical-align: top;

			p {
				color: $grey;
				padding: 0.25rem 0;
			}
		}

		input + button {
			margin-top: 0.5rem;
		}
	}
}

.button-group.is-checklist {
	display: grid;
	grid-auto-flow: column;

	@include mobile() {
		grid-auto-flow: row;
		gap: 0.25rem;

		.button {
			border-radius: $radius !important;
		}
	}

	.button {
		padding: 0rem !important;
		height: 30px;
		border-color: $border !important;
	}

	.is-check-button {
		&.is-active {
			.fa:before {
				content: "\f058";
			}
		}
	}
}
