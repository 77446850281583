@use "sass:math";

.template-selector-tiles {
	$tileWidth: 300px;

	display: grid;
	grid-template-columns: repeat(auto-fill, minmax($tileWidth, 1fr));
	gap: 1rem;

	.template-tile {
		border: 1px solid $border;
		border-radius: $radius;
		display: grid;
		grid-template-rows: max-content 1fr max-content;
		overflow: hidden;

		.title {
			background: $grey-bg;
			padding: 1rem;
			border-bottom: 1px solid $border;
		}

		.preview {
			padding: 1rem;
			max-height: 200px;
			overflow: hidden;
			position: relative;

			&::after {
				display: block;
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 30%;
				background: linear-gradient(180deg, transparent, $white);
			}
		}

		.actions {
			padding: 1rem;

			.edit-actions {
				display: grid;
				grid-template-columns: repeat(auto-fill, minmax(math.div($tileWidth, 2), 1fr));
				padding-bottom: 0.5rem;
			}
		}

		&.is-active {
			border-color: $primary;
			background: $primary-bg;

			.title {
				background: darken($primary-bg, 10%);
				border-bottom-color: $primary;
			}

			.preview {
				&:after {
					background: linear-gradient(180deg, transparent, $primary-bg);
				}
			}
		}
	}
}

.templates-edit-form {
	display: grid;
	grid-template-rows: max-content 1fr max-content;
	height: 100%;
}

.template-select-form {
	display: grid;
	grid-template-areas:
		"template-selector template-preview"
		"template-actions template-actions";
	grid-template-columns: max-content 1fr;
	gap: 1rem 3rem;

	.template-selector {
		grid-area: template-selector;

		.divider {
			margin: 0.5rem 0;
			border: 0;
			border-top: 1px solid $grey-light;
		}
	}
	.template-preview {
		grid-area: template-preview;

		.html-content {
			display: block;
			padding: 1rem;
			border: 1px solid $border;
		}
	}
	.template-select-actions {
		grid-area: template-actions;
	}

	.title {
		margin-bottom: 1rem;
	}
}
