input[type="checkbox"] {
	margin: 0;
	position: relative;
	width: 16px;
	height: 16px;
	cursor: pointer;

	&::before {
		position: absolute;
		content: " ";
		// background: $white;
		width: 100%;
		height: 100%;
		display: block;
		border: 1px solid $action;
		background: $white;
		border-radius: $radius-small;
	}

	&::after {
		position: absolute;
		content: " ";
		width: 12px;
		height: 6px;
		border-left: 3px solid;
		border-bottom: 3px solid;
		border-color: $action;
		transform: rotate(-45deg);
		top: 3px;
		left: 2px;
		opacity: 0;
	}

	&:checked {
		&::after {
			opacity: 1;
		}
	}

	&:disabled {
		&::before {
			border-color: $grey-light;
			background: $grey-light;
		}
		&::after {
			border-color: $grey;
		}
	}
}
