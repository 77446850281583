.inputbase {
	outline: 0;
	padding: 0.5rem;
	display: block;
	max-width: 100%;
	width: 100%;
	height: $inputHeight;

	font-family: $family-sans-serif;
	font-size: $size-6;
	background: $white;
	border: 1px solid $border;
	border-radius: $radius;
	color: $text;

	cursor: pointer;

	&:hover,
	&:focus {
		// background: transparent;
		border-color: $grey;
	}
	&:focus {
		border-color: $primary;
	}

	&:disabled {
		border-color: $border;
		color: $grey;
		cursor: not-allowed;
	}

	&::placeholder {
		font-style: normal;
		font-size: 1rem;
		color: $placeholder;
	}
}
