.documents-filter {
	position: relative;
	display: grid;
	gap: 0.5rem;
	grid-template-columns: 1fr max-content;
	align-items: center;
	max-width: 500px;

	grid-template-areas:
		"text toggle"
		"active count";

	.text-input {
		grid-area: text;
		.form-control {
			margin: 0 !important;
		}
	}

	.toggle {
		grid-area: toggle;
	}

	.filter-count {
		grid-area: count;
	}

	.filter-options-list {
		position: absolute;
		top: 0;
		width: 100%;
		background: white;
		border-radius: $radius;
		border: 1px solid $border;
		box-shadow: 0 0 1rem rgba($color: $black, $alpha: 0.25);
		padding: 1rem;
		z-index: 999;

		.form-control {
			margin-bottom: 0.5rem;
		}

		.close-filters {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
		}

		.footer {
			border-top: 1px solid $border;
			text-align: right;
			padding-top: 1rem;
			margin-top: 1rem;
		}
	}

	.active-filters {
		grid-area: active;

		.is-active-filter-button {
			margin-bottom: 0.5rem;
			text-transform: none;
			padding: 0.2rem 0.4rem;
			margin-left: 0;
			margin-right: 0.2rem;
			margin-bottom: 0.2rem;
		}
	}
}

.filter-count {
	text-align: right;
	font-size: $size-7;
	color: $text-light;
	align-self: baseline;
}

.document-widget {
	padding: 1rem;

	&.is-ap {
		border-color: $color-ap;

		.document-ap {
			grid-area: document-ap;
			border: 1px solid $vibrant-lemon;
			background: $yellow-bg;
			padding: 1rem;
			border-radius: $radius;
		}
	}
	&.is-kbvr {
		border-color: $color-kbvr;
	}
	&.is-notiz {
		border-color: $color-notiz;
	}
	&.is-ev {
		border-color: $color-ev;
	}
	&.is-jpg {
		border-color: $color-jpg;

		.jpg-options {
			grid-area: jpg-options;
			display: grid;
			grid-auto-flow: row;
			gap: 0.5rem;
			border: 1px solid $vibrant-lemon;
			background: $yellow-bg;
			padding: 1rem;
			border-radius: $radius;

			.header {
				display: grid;
				gap: 1rem;
				grid-template-columns: max-content 1fr max-content;
				align-items: center;

				.header-icon {
					background: $vibrant-yellow;
					width: 2rem;
					height: 2rem;
					border-radius: 10rem;
					text-align: center;
					line-height: 2rem;
				}
			}

			@include mobile() {
				.buttons {
					display: grid;
					gap: 0.5rem;

					.button {
						margin: 0;
					}
				}
			}
		}
	}
	&.is-upload {
		border-color: $color-upload;
	}
	&.is-an {
		border-color: $color-an;
	}
	&.is-disabled {
		position: relative;
		.disabled-document {
			background: rgba($color: #ffffff, $alpha: 0.75);
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1000;
			pointer-events: none;

			span {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				background: white;
				height: 60px;
				display: grid;
				align-content: center;
				justify-content: center;
				padding: 1rem;
				font-weight: $weight-bold;
			}
		}
	}
}

.document-form {
	display: grid;
	gap: 0.5rem;
	grid-template-rows: 1fr max-content;

	.document-form-body {
		display: grid;
		gap: 0.5rem;
		grid-template-columns: max-content 1fr max-content;
		align-items: center;

		grid-template-areas:
			"document-tag title actions"
			"document-changes document-changes document-changes"
			"sharing sharing sharing"
			"document-agt document-agt document-agt"
			"document-intro document-intro document-intro"
			"document-ap document-ap document-ap"
			"content content content"
			"document-uploads document-uploads document-uploads";

		@include mobile() {
			grid-template-columns: max-content 1fr;
			grid-template-areas:
				"document-tag title"
				"document-changes document-changes"
				"actions actions"
				"sharing sharing"
				"document-agt document-agt"
				"document-intro document-intro"
				"document-ap document-ap"
				"content content"
				"document-uploads document-uploads"
				"document-edit-state document-edit-state";
		}
	}

	.document-tag {
		grid-area: document-tag;
	}
	.title {
		grid-area: title;
	}
	.document-actions {
		grid-area: actions;
		padding-right: 50px;
	}
	.document-changes {
		grid-area: document-changes;
		font-size: $size-7;
		color: $text-light;

		span + span {
			padding-left: 1rem;
		}
	}
	.sharing {
		grid-area: sharing;
	}
	.document-agt {
		grid-area: document-agt;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
	}
	.document-intro {
		grid-area: document-intro;
		padding: 1rem 0 0.5rem;
		color: $text-light;
	}
	.document-content {
		grid-area: content;

		.html-content {
			display: block;
			border: 1px solid $border;
			border-radius: $radius $radius 0 0;
			padding: 1rem;
			// font-size: $size-5;
			// font-weight: $weight-semibold;

			strong {
				font-weight: $weight-bold;
			}

			&.is-empty {
				font-style: italic;
				color: $text-light;
				font-size: $size-6;
			}
		}

		& + .document-uploads {
			border-radius: 0 0 $radius $radius;
			margin-top: calc(-0.5rem - #{$radius});
			background: $white;
			position: relative;
			z-index: 4;
		}
	}
	.document-uploads {
		grid-area: document-uploads;
		border: 1px solid $border;
		border-radius: $radius;
		padding: 1rem;
		margin-top: -0.5rem;
		color: $text-light;

		display: grid;
		grid-template-areas:
			"title uploader"
			"upload-items upload-items";
		grid-template-columns: 1fr max-content;

		.uploads-title {
			display: grid;
			grid-auto-flow: column;
			gap: 0.5rem;
			justify-content: start;
			align-items: center;
		}

		.file-uploader {
			background: transparent;
			padding: 0;
		}

		.upload-items {
			grid-area: upload-items;
			padding-top: 1rem;
			display: grid;
			gap: 0.5rem;
			grid-auto-flow: row;

			.document-upload {
				border: 1px solid $border;
				display: grid;
				grid-template-columns: 1fr max-content;
				border-radius: 50rem;
				overflow: hidden;

				.download-button {
					box-shadow: none;
					display: grid;
					gap: 0.5rem;
					grid-template-columns: max-content 1fr max-content;
					align-items: center;
					text-transform: none;

					&:hover,
					&:focus {
						background: transparent;
						color: $primary;

						.filetype-icon {
							box-shadow: inset 0 0 2rem rgba($color: $black, $alpha: 0.5);
						}
					}

					.file-name,
					.file-size {
						text-align: left;
						font-weight: $weight-normal;
					}
				}

				.delete-button {
					padding: 0 1rem;

					&:hover,
					&:focus {
						color: $error;
					}
				}
			}
		}
	}
	.document-edit-state {
		grid-area: document-edit-state;
		text-align: right;
		padding-top: 0.5rem;
	}
}

.is-user-notiz {
	.document-form {
		grid-template-areas:
			"document-changes document-changes actions"
			"document-agt document-agt document-agt"
			"content content content"
			"document-uploads document-uploads document-uploads"
			"document-edit-state document-edit-state document-edit-state";

		@include mobile() {
			grid-template-areas:
				"document-changes document-changes"
				"actions actions"
				"document-agt document-agt"
				"content content"
				"document-uploads document-uploads"
				"document-edit-state document-edit-state";
		}
	}
}

// JPG 2025
.modal.is-large.is-jpg {
	width: 80vw;
	.document-widget.is-jpg {
		height: 100%;
	}
}
.document-widget.is-jpg {
	.document-form.is-reading {
		.document-form-body {
			grid-template-areas:
				"document-tag title actions"
				"document-changes document-changes document-changes"
				"sharing sharing sharing"
				"document-agt document-agt document-agt"
				"document-intro document-intro document-intro"
				"content content content"
				"document-uploads document-uploads document-uploads"
				"ziele-intro ziele-intro ziele-intro"
				"ziele ziele ziele"
				"fokusthemen fokusthemen fokusthemen"
				"document-edit-state document-edit-state document-edit-state";
		}
	}

	.document-form {
		grid-template-rows: 1fr max-content;
		gap: 0;
		height: 100%;
	}

	.document-form-body {
		overflow-y: auto;
		grid-template-areas:
			"document-tag title actions"
			"document-changes document-changes document-changes"
			"sharing sharing sharing"
			"document-agt document-agt document-agt"
			"document-intro document-intro ziele-intro"
			"content content ziele"
			"document-uploads document-uploads ziele"
			"fokusthemen fokusthemen fokusthemen"
			"document-edit-state document-edit-state document-edit-state";

		.ziele {
			grid-area: ziele;
			align-self: flex-start;

			.ziele-list.is-readonly {
				max-width: 100%;
			}
		}
		.ziele-intro {
			grid-area: ziele-intro;
			padding: 1rem 0 0.5rem;
			color: $text-light;
		}
		.fokusthemen {
			grid-area: fokusthemen;
			align-self: flex-start;
			padding: 1rem 0;

			.fokusthemen-table-container {
				padding: 0.5rem 0;
			}

			.title {
				padding-bottom: 0.5rem;
				display: block;
			}
		}
	}

	.document-form-footer {
		.modal-footer {
			margin-top: 0;
		}
	}
}
